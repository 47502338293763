import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { pullDataMethod } from '../Redux/StateModel/DetectionPageModel/DetectionPageModel'

// Store
import { AppState } from '../Redux';

// Components
import LoadDetectionFromKusto from '../components/LoadDetectionFromKusto/LoadDetectionFromKusto';
import { argumentFromUrl } from '../components/OCEDashBoard/OCEDashBoard';
import { DetectionListConstant } from '../Redux/Constants/GeneralConstants';
import { IMsalContext } from '@azure/msal-react';
import { Moment } from 'moment';
import { loadDetectionsPostApiCall } from '../Redux/ApiCalls/DetectionActions';


export interface IProps {
  urlParameters: argumentFromUrl;
  detectionListConstant: DetectionListConstant;
  query: string;
}


const mapStateToProps = (state: AppState, ownProps: IProps) => {
  return {
    query: ownProps.query
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
    loadDetectionsPostApiCall: (msalContext: IMsalContext, detectionListConstant: DetectionListConstant, options: any = {}) =>
      dispatch(loadDetectionsPostApiCall(msalContext, detectionListConstant, options)),

  }
}

const LoadDetectionFromKustoContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoadDetectionFromKusto);

export default LoadDetectionFromKustoContainer;
