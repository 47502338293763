import { Moment } from 'moment';
import { attr, Model, ModelType } from 'redux-orm'
import { UPDATE_TIP_TRANSACTION } from '../../Constants/Actions';


export interface TipTransactionTableFields {
    UniqIdentifier: string,
    Application: string,
    TransactionType: string,
    TableOrTopic: string,
    TipName: string,
    Created: Moment
}

export interface TipTransaction {
    Application: string,
    TransactionType: string,
    TableOrTopic: string,
    TipName: string,
    Created: Moment
}

interface UpdateTipTransactionTable {
    type: typeof UPDATE_TIP_TRANSACTION;
    tipTransactions: TipTransaction[];
}



export type TipTransactionModelActionTypes =
    UpdateTipTransactionTable

//Action Functions

export function updateTipTransactions(tipTransactions: TipTransaction[]): TipTransactionModelActionTypes {
    return {
        type: UPDATE_TIP_TRANSACTION,
        tipTransactions,
    }
}

// @ts-ignore
export class TipTransactionTable extends Model<typeof TipTransactionTable, TipTransactionTableFields> {
    static modelName = 'TipTransactionTable' as const;
    static fields = {
        application: attr(),
        transactionType: attr(),
        tableOrTopic: attr(),
        tipName: attr(),
        created: attr()
    };

    static options = {
        idAttribute: 'uniqIdentifier' as const
    };

    // @ts-ignore
    static reducer(action: TipTransactionModelActionTypes, sparkFilterTable: ModelType<TipTransactionTable>) {
        switch (action.type) {
            case UPDATE_TIP_TRANSACTION:
                sparkFilterTable.delete();
                action.tipTransactions.forEach(val => {
                    const UniqIdentifier : string = val.Application + '_' + val.TransactionType + '_' + val.TableOrTopic + '_' + val.TipName;
                    const obj : TipTransactionTableFields = {
                        ...val,
                        UniqIdentifier : UniqIdentifier
                    };
                    sparkFilterTable.create(obj)
                })
                break;
            default:
                break;
        }
    }
}