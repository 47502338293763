import { withMsal, WithMsalProps } from '@azure/msal-react';
import moment, { Moment } from 'moment';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react';
// Fabric UI
import { IBasePicker, ITag, TagPicker } from 'office-ui-fabric-react/lib/Pickers';
import { mergeStyles } from 'office-ui-fabric-react/lib/Styling';
import React from 'react';
import { Ref } from 'redux-orm';
// Container
import DetectionListContainer from '../../containers/DetectionPageContainer';
import { DetectionListConstant } from '../../Redux/Constants/GeneralConstants';
import { pullDataMethod } from '../../Redux/StateModel/DetectionPageModel/DetectionPageModel';
import { WorkloadTable } from '../../Redux/StateModel/WorkloadModel/WorkloadModel';
import { argumentFromUrl, urlLinks } from '../OCEDashBoard/OCEDashBoard';
import { WorkloadPicker } from './WorkloadPicker';


export interface IProps {
  // @ts-ignore
  workloads: Ref<WorkloadTable>[];
  urlLink: urlLinks;
  urlParameters: argumentFromUrl;
  detectionListConstant: DetectionListConstant;
  thunkLoadDetectionsApiCall: any;
}

type localState = {
  _testTags: ITag[];
  selectedWorkloads: ITag[];
  startTime: Moment;
  stopTime: Moment;
}

const rootClass = mergeStyles({
});

class WorkloadFilter extends React.Component<IProps & WithMsalProps, localState> {

  readonly state: localState = {
    _testTags: [],
    selectedWorkloads: [],
    startTime: moment(),
    stopTime: moment()
  }

  private _picker = React.createRef<IBasePicker<ITag>>();

  constructor(cProps: IProps & WithMsalProps) {
    super(cProps);
    let startTime = moment(cProps.urlParameters.timeRangeStart, moment.ISO_8601, true);
    if (!startTime.isValid())
      startTime = moment(Date.now() - 7 * 24 * 3600 * 1000).startOf('day');
    this.state = {
      _testTags: this.props.workloads.map(item => item.workload).map(item => ({ key: item, name: item })),
      selectedWorkloads: [],
      startTime: startTime,
      stopTime: moment()
    }
  }

  private generateWorkLoadInfo(workLoads: string[]) {
    let unTriagePaging = "?workloads=";
    workLoads.forEach(val => { unTriagePaging = unTriagePaging + val + "," });
    unTriagePaging = unTriagePaging.substring(0, unTriagePaging.length - 1);
    return unTriagePaging;
  }

  private loadAllWorkLoads(props: IProps, state: localState, startTime: Moment, stopTime: Moment,) {
    props.thunkLoadDetectionsApiCall(this.props.msalContext, startTime, stopTime, this.generateWorkLoadInfo(state._testTags.map(value => { return value.name })), props.detectionListConstant, pullDataMethod.pullLatest);
  }

  private loadSomeWorkLoads(props: IProps, state: localState, startTime: Moment, stopTime: Moment,) {
    props.thunkLoadDetectionsApiCall(this.props.msalContext, startTime, stopTime, this.generateWorkLoadInfo(state.selectedWorkloads.map(value => { return value.name })), props.detectionListConstant, pullDataMethod.pullLatest);
  }

  public render() {
    if (this.props.workloads.length !== this.state._testTags.length) {
      this.setState({ _testTags: this.props.workloads.map(item => item.workload).map(item => ({ key: item, name: item })) });
    }

    return (
      <div className={rootClass}>
        <WorkloadPicker
          componentRef={this._picker}
          onChange={this.setSelectedItems(this.setState.bind(this))}
          itemLimit={200}
          itemList={this.state._testTags}
        />
        <DefaultButton text="Load All Workloads" onClick={() => this.loadAllWorkLoads(this.props, this.state, this.state.startTime, this.state.stopTime)} />
        <PrimaryButton text="Load WorkLoads" onClick={() => this.loadSomeWorkLoads(this.props, this.state, this.state.startTime, this.state.stopTime)} allowDisabledFocus disabled={false} checked={false} />
        {/* <DetectionPageContainer */}
        <DetectionListContainer
          urlParameters={this.props.urlParameters}
          detectionListConstant={this.props.detectionListConstant}
          msalContext={this.props.msalContext}
        />
      </div>

    );
  }

  private setSelectedItems(setState2: (state: any) => void): ((items?: ITag[]) => void) {
    return (items?: ITag[]) => {
      let values: ITag[] = [];
      if (items !== undefined) {
        values = items as ITag[];
      }
      setState2({ selectedWorkloads: values });
    };
  }
}

export default withMsal(WorkloadFilter);
