import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { IMsalContext } from '@azure/msal-react';
import { DetectionResultIdArray } from '../Redux/ApiCalls/DetectionListToolbarActions';
import { kustoDb } from '../vanquishConfig';
import { loginRequest, renewTokenDalRequest, renewTokenKustoRequest } from './adalConfig';

export interface IHash {
    [details: string] : string
  } 
  
  export const commentStatus: IHash = {
    "Normal Practice": "Resolved",
    'Abnormal Practice': "Resolved",
    "Insufficient Data": "Resolved",
    "Tuning Required/Filtered": "Resolved",
    "Emerging Activity to be learned in": "Resolved",
    "Bug": "Resolved",
    "Poor Practice": "Resolved",
    "Debugging/Break-Glass": "Resolved",
    "AttackBot": "Resolved",
    "Security Monitoring Testing": "Resolved",
    "Pen Tester": "Resolved",
    "Incident": "Resolved",
    "In Progress": "Active",
    "Waiting for Response": "Active",
    "Escalated to IR": "Active"
  }   

/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */

export function RequestAuthToken(contextType: IMsalContext, scopes: any) :Promise<string | void> {
    
    // Silently acquires an access token which is then attached to a request
    return contextType.instance
        .acquireTokenSilent({
            ...scopes,
            account: contextType.accounts[0],
        })
        .then((response) => {
            return response.accessToken as string
        }).catch(function (error) {
            //Acquire token silent failure, and send an interactive request
            if (error instanceof InteractionRequiredAuthError) {
                contextType.instance.loginRedirect(loginRequest).catch(e => {
                    console.log(e);
                });
            }
            console.log(error);
          });;
}

const tokenIsValid = (token : string | null) : boolean => {    
    if(token == undefined || token == "" || token == null || token == "null")  
        return false;  
    const decode = JSON.parse(atob(token.split('.')[1]));
    if (decode.exp * 1000 < new Date().getTime()) {
        return false
    }
    return true
};

const GetAuthorizationHeaders = async (contextType: IMsalContext, url:string) : Promise<HeadersInit | null> => {
    let aadToken : string | void;
    if(url.startsWith(kustoDb))
    {
        aadToken = await RequestAuthToken(contextType, renewTokenKustoRequest);
    }
    else{
        aadToken = await RequestAuthToken(contextType, renewTokenDalRequest);
    } 
    if(aadToken == null)
        return null;
    const AuthStr = 'Bearer '.concat(aadToken);

    const dalAuth = localStorage.getItem("dal-auth");
    if(tokenIsValid(dalAuth))
        return { "content-type": "application/json", Authorization: AuthStr, "dal-auth": dalAuth} as HeadersInit;
    else
        return { "content-type": "application/json", Authorization: AuthStr} as HeadersInit;
};

function handleResponse(response: { text: () => Promise<any>; headers:any; ok: any; status: number; statusText: any }) {
    return response.text().then(text  => {
        var data
        try{
            data = text && JSON.parse(text)
        }catch (e){
            data = text
        }
      
      if (!response.ok) {
          if ([401, 403].includes(response.status)) {
              // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
              localStorage.removeItem('dal-auth');
          }
  
          const error = (data && data.message) || response.statusText;
          throw Error(error)
      }
      localStorage.setItem("dal-auth", response.headers.get("dal-auth"));
      return data;
    });
  }

export const addAadTokenToPostRequest = async (contextType: IMsalContext, url: string, options: any = {}) => {
    let header = await GetAuthorizationHeaders(contextType, url);
    if(header == null)
        throw Error("Faied to get Auth token")
    const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify(options),
        headers: header
    };
    //let apiPromise: Promise<any> = axiosInstance.post(url, options, header);
    const  apiPromise: Promise<any> = fetch(url, requestOptions).then(handleResponse);;
    var apiResponse
    try{
        apiResponse = await apiPromise;
    }catch (e) {
        throw e;
    }

    

    return apiResponse;
};

export const addAadTokenToDetectionPostRequest = (contextType: IMsalContext, url: string, selectStatus: string = "", comment: string = "", detections: DetectionResultIdArray, options: any = {}) => {
    let status = commentStatus[selectStatus]
    const dalAuth = localStorage.getItem("dal-auth")
    return addAadTokenToPostRequest(contextType, url, {detections, message: comment, substatus: selectStatus, status: status});
}


export const addAadTokenToGetRequest = async (contextType: IMsalContext, url: string, options: any = {}) => {
    //Append the DAL token
    let header = await GetAuthorizationHeaders(contextType, url);
    if(header == null)
        throw Error("Faied to get Auth token")
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: header
    };

    const  apiPromise: Promise<any> = fetch(url, requestOptions).then(handleResponse);;
    var apiResponse
    try{
        apiResponse = await apiPromise;
    }catch (e) {
        throw e;
    }

    //make the api call and store the DAL token
    const apiCallData = await apiPromise;

    return apiCallData;
}


