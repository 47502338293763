import { Ref, TableState } from "redux-orm"
import { ICMTable } from "./ICMModel/ICMModel"

export interface IcmInfo {
  OwningContactAlias: string
  IncidentId: number | string
  OwningTeamName: string
  Status: string
}

// Helper functions used in selectors for different models in StateModel
// @ts-ignore
export const buildIcMInfo = (icmData: Ref<ICMTable>): IcmInfo => {
  return {
    OwningContactAlias: (icmData !== undefined) ? icmData.OwningContactAlias : "",
    IncidentId: (icmData !== undefined) ? icmData.IncidentId : "",
    OwningTeamName: (icmData !== undefined) ? icmData.OwningTeamName : "",
    Status: (icmData !== undefined) ? icmData.Status : "",
    Severity: (icmData !== undefined) ? icmData.Severity : ""
  } as IcmInfo
}