import { Ref, TableState } from "redux-orm";
import { createSelector } from 'reselect';
import { DetectionResultIdArray } from "../../ApiCalls/DetectionListToolbarActions";
import { DetectionListConstant } from "../../Constants/GeneralConstants";
import { DetectionFilterTable } from "../DetectionFilterModel/DetectionFilterModel";
import { getDetectionsPageByOwner } from "../DetectionPageModel/DetectionPageSelector";
import { getDetectionSummaries, generateDescription } from "../DetectionSummaryModel/DetectionSummarySelector";
import { getICMData } from "../ICMModel/ICMSelector";
import { buildIcMInfo } from "../SelectorHelper";
import { DetectionTable, DetectionTableFields } from "./DetectionModel";

export interface IProps {
    // @ts-ignore
    detectionTable: TableState<typeof DetectionTable>;
}

export interface detectionOwner {
    detectionOwner: string;
}

export interface SelectedDetections {
    [uniqueId: string]: DetectionTableFields
}

export interface GetDetectionListConstant {
    detectionListConstant: DetectionListConstant;
}

export interface Filters {
    // @ts-ignore
    detectionListFilterTable: TableState<typeof DetectionFilterTable>;
}

export const getDetectionListConstant = (detectionListConstant: GetDetectionListConstant): DetectionListConstant => {
    return detectionListConstant.detectionListConstant
}

// @ts-ignore
export const getAllFilters = (props: Filters): Ref<DetectionFilterTable>[] => {
    return Object.values(props.detectionListFilterTable.itemsById)
}

export const getDetections = (props: IProps): DetectionTableFields[] => {
    return Object.values(props.detectionTable.itemsById) as DetectionTableFields[]
}

export const getDetectionOwner = (detectionOwner: detectionOwner): string => {
    return detectionOwner.detectionOwner;
}

export const getDetectionsByOwner = createSelector(
    [getDetections, getDetectionOwner],
    (detections, owner): DetectionTableFields[] => {
        return detections.filter(val => owner === val.detectionOwner)
    }
)

// @ts-ignore
const validFilter = (fieldValue: string, filter: Ref<DetectionFilterTable>): boolean => {
    if (filter.applied && !filter.ctrlApplied)
        return fieldValue === filter.filterName
    else if (filter.applied && filter.ctrlApplied)
        return fieldValue !== filter.filterName
    else
        return true
}

// @ts-ignore
const applyFilterToDetection = (detection: DetectionTableFields, filter: Ref<DetectionFilterTable>): boolean => {

    const field = filter.filterSubGroup;
    if (detection.hasOwnProperty(field))
        return validFilter(detection[field], filter);
    else if (detection.hasOwnProperty("additional_data") && detection.additional_data.hasOwnProperty(field))
        return validFilter(detection.additional_data[field], filter);
    else
        return !filter.applied
}

export const getDetectionWithSummary = createSelector(
    [getDetectionsByOwner, getDetectionSummaries],
    (detections, summary): DetectionTableFields[] => {
        return detections.map(detection => {
            const descriptionSummaryTemplate = summary.itemsById[detection.detection_type];
            const description = generateDescription(detection, descriptionSummaryTemplate)
            return ({
                ...(detection),
                description: description

            }) as DetectionTableFields;

        })
    }
)

export const getDetectionWithIcmData = createSelector(
    [getDetectionWithSummary, getICMData],
    (detections, icm): DetectionTableFields[] => {
        return detections.map(detection => {
            const icmInfo = buildIcMInfo(icm.itemsById[detection.id]);
            const ranTime = (new Date(detection.date_time_utc)).valueOf();
            const ingestTime = (new Date(detection.detection_result_time_bin)).valueOf();
            const delay = (ingestTime - ranTime) / 60000;
            const additionalData = (detection.additional_data !== undefined) ? detection.additional_data : {};
            const UserToPivot = (additionalData.UserToPivot !== undefined) ? additionalData.UserToPivot : "";
            const ProcessToPivot = (additionalData.ProcessToPivot !== undefined) ? additionalData.ProcessToPivot : "";
            const Alias = (detection.remediation_action !== undefined) ? detection.remediation_action : "";
            const WindowStartTime = (additionalData.WindowStart !== undefined) ? additionalData.WindowStart : "";

            return ({
                ...(icmInfo),
                ...(detection),
                delay: delay,
                UserToPivot: UserToPivot,
                ProcessToPivot: ProcessToPivot,
                Alias: Alias,
                WindowStartTime: WindowStartTime
            }) as DetectionTableFields;
        });
    }
)

// Gets the icm data for all detections regardless of the detection "page"
export const getAllDetectionsWithSimpleIcMData = createSelector(
    [getDetections, getICMData],
    (detections, icmDataEntries): DetectionTableFields[] => {
        return detections.map(detection => {
            const icmInfo = buildIcMInfo(icmDataEntries.itemsById[detection.id]);
            const Alias = (detection.remediation_action !== undefined) ? detection.remediation_action : "";

            return ({
                ...(icmInfo),
                ...(detection),
                Alias: Alias
            }) as DetectionTableFields;
        })
    }
)

export const getFilterByOwner = createSelector(
    [getDetectionOwner, getAllFilters],
    (owner, filters) => {
        return filters.filter(filter => {
            return filter.detectionFilterOwner == owner;
        })
    }
)

export const getFilterSubGroupsByOwner = createSelector(
    [getFilterByOwner],
    (filters) => {
        return Array.from(new Set(filters.map(val => val.filterSubGroup))) as string[]
    }
)

export const  applyFilterToTheDetections = createSelector(
    [getDetectionWithIcmData, getFilterByOwner],
    (detections, filters): DetectionTableFields[] => {
        return detections.filter(detection => {
            let isValid = true;
            filters.forEach(filter => {
                isValid = isValid && applyFilterToDetection(detection, filter);
            });
            return isValid;
        })
    }
)

export const getSelectedDetections = createSelector(
    [applyFilterToTheDetections],
    (detections): SelectedDetections => {
        let selected: SelectedDetections = {};
        detections.filter(detection => detection.isSelected).forEach(detection => {
            selected[detection.uniqueId] = detection;
        })
        return selected;
    }
)

export const getSelectedDetectionPostData = createSelector(
    [getSelectedDetections],
    (detections: SelectedDetections): DetectionResultIdArray => {
        let postData: DetectionResultIdArray = [];
        Object.values(detections).forEach(element => {
            postData.push({
                datetimeutc: 0,
                detectiontype: element.detection_type,
                entity: element.target_entity,
                id: element.id,
                timebin: 0,
                type: element.target_entity_type,
                workload: element.workload
            })
        });
        return postData;
    }
)
