import { connect } from 'react-redux';
import { Action } from 'redux';
import { Moment } from 'moment';
import { ThunkDispatch } from 'redux-thunk';

// Store
import { AppState } from '../Redux';
import { thunkLoadDetectionsApiCall } from '../Redux/ApiCalls/DetectionActions';
import { thunkLoadDetectionSummary } from '../Redux/ApiCalls/TriageActions';
import { pullDataMethod } from '../Redux/StateModel/DetectionPageModel/DetectionPageModel';

// Components
import TriageAlerts from '../components/TriageAlerts/TriageAlerts';
import { paramsInterface, argumentFromUrl } from '../components/OCEDashBoard/OCEDashBoard';
import { DetectionListConstant } from '../Redux/Constants/GeneralConstants';
import { IMsalContext } from '@azure/msal-react';


export interface IProps {
  urlExtension: paramsInterface;
  urlParameters: argumentFromUrl;
}

const mapStateToProps = (state: AppState, ownProps: IProps) => {
  return {
    urlExtension: ownProps.urlExtension,
    urlParameters: ownProps.urlParameters
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
    thunkLoadDetectionsApiCall: (msalContext: IMsalContext, startTime: Moment, stopTime: Moment, extraParameters: string, detectionListConstant: DetectionListConstant, pullDataMethod: pullDataMethod) =>
      dispatch(thunkLoadDetectionsApiCall(msalContext, startTime, stopTime, extraParameters, detectionListConstant, pullDataMethod)),
    thunkLoadDetectionSummary: (contextType: IMsalContext) =>
      dispatch(thunkLoadDetectionSummary(contextType))
  }
}

const TriageAlertsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TriageAlerts);

export default TriageAlertsContainer;
