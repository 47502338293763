

//ADAL config
export const endpoint = 'https://login.microsoftonline.com'
export const appId = 'cc59d35d-1694-4458-901b-4e2faab7dd63'
export const tenant = 'prdtrs01.prod.outlook.com'
export const tenantId = 'cdc5aeea-15c5-4db6-b079-fcadd2505dc2'
export const kustoDb = "https://o365security.kusto.windows.net"
export const kustoDbQuery = kustoDb + "/v2/rest/query"


//Lagacy UI
export const vanquishURL = "https://vanquish.office.net/#";
//New UI
export const newVanquishURL = "https://vanquishtriagedetection.office.net";
//DAL
export const dalbaseURL = "https://vanquish-dal.office.net/";
export const dalURL = dalbaseURL + "kustoapi/Kusto/";

export const kustoHunting = "https://dataexplorer.azure.com/dashboards/48f090cf-e021-42d1-8459-970e81dfbbec#a994bdf3-7cea-4507-b387-b3bb55ebe6db"


//Enginering Hub ReadMe
export const engBase = "https://eng.ms/docs/security-compliance-identity-and-management-scim/m365-security-compliance-and-management/m365-security-engineering/security-services/m365-vanquish/vanquish-tsgs/detections/"
export const engHubReadMe = engBase + "readme"

export const icmEnabled = true
