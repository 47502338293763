// Action constants

// DetectionFilterModel Actions
export const ADD_DETECTIONS_FILTER = 'ADD_DETECTIONS_FILTER';
export const UPDATE_DETECTIONS_FILTER = 'UPDATE_DETECTIONS_FILTER';

// DetectionModel Actions
export const ADD_DETECTIONS = 'ADD_DETECTIONS';
export const SELECT_DETECTIONS = 'SELECT_DETECTIONS';
export const UNSELECT_ALL_DETECTIONS = 'UNSELECT_ALL_DETECTIONS';

// DetectionPageModel Actions
export const ADD_DETECTIONS_PAGE = 'ADD_DETECTIONS_PAGE';
export const UPDATE_PAGE = 'UPDATE_PAGE';

// ICMModel Actions
export const ADD_ICMDATA = 'ADD_ICMDATA';

// TriageModel Actions
export const ADD_DETECTION_INFO_SUMMARY_TO_STORE = 'ADD_DETECTION_INFO_SUMMARY_TO_STORE';

//DetectionSummaryModel Actions
export const ADD_DETECTIONS_SUMMARY = 'ADD_DETECTIONS_SUMMARY';

//SparkFilterModel Actions
export const UPDATE_SPARK_FILTER = 'UPDATE_SPARK_FILTER';
export const UPDATE_SPARK_FILTERS = 'UPDATE_SPARK_FILTERS';

//UpdateTipTransactionTable Actions
export const UPDATE_TIP_TRANSACTION = 'UPDATE_TIP_TRANSACTION';

//WorkloadModel Actions
export const ADD_WORKLOADS = 'ADD_WORKLOADS';
export const GET_WORKLOAD = 'GET_WORKLOAD';

// EngagementInfo Model actions
export const ADD_ENGAGEMENT_INFO = 'ADD_ENGAGEMENT_INFO';
export const GET_ENGAGEMENT_INFO = 'GET_ENGAGEMENT_INFO';
export const UPDATE_ENGAGEMENT_INFO = 'UPDATE_ENGAGEMENT_INFO';