import { connect } from 'react-redux';
import { Dispatch } from 'redux';

// Store
import { AppState } from '../Redux';


// Components
import DetectionListFilterToggle from '../components/DetectionList/SupportingObjects/DetectionListFilterToggle';
import { DetectionFilterTable } from '../Redux/StateModel/DetectionFilterModel/DetectionFilterModel';
import { Ref } from 'redux-orm';
import { DetectionFilterDataModelActionTypes, updateFilterClick } from '../Redux/StateModel/DetectionFilterModel/DetectionFilterActions';
import { DetectionFilterTableField } from '../Redux/StateModel/DetectionFilterModel/DetectionFilterInterface';
import { getFilterCount } from '../Redux/StateModel/DetectionFilterModel/DetectionFilterSelector';
import { DetectionListConstant } from '../Redux/Constants/GeneralConstants';

export interface IOwnProps {
  // @ts-ignore
  filter: Ref<DetectionFilterTable>,
  detectionPageData: DetectionListConstant,
}

const mapStateToProps = (state: AppState, ownProps: IOwnProps) => {
  return {
    filter: ownProps.filter,
    count: getFilterCount({
      detectionListFilterTable: state.orm.DetectionFilterTable,
      detectionTable: state.orm.DetectionTable,
      detectionSummaryTable: state.orm.DetectionSummaryTable,
      icmTable: state.orm.ICMTable,
      detectionOwner: ownProps.detectionPageData.pageName,
      filter: ownProps.filter,
    })
  }
}

const mapDispatchToProps = (dispatch: Dispatch<DetectionFilterDataModelActionTypes>) => {
  return {
    updateFilterClick: (event: any, filter: DetectionFilterTableField) => dispatch(updateFilterClick(event, filter))
  }
}

const DetectionListFilterToggleContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DetectionListFilterToggle);

export default DetectionListFilterToggleContainer;
