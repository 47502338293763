import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { RootReducer } from "./StateModel/RootReducers";


export type AppState = ReturnType<typeof RootReducer>;

const store = createStore(RootReducer,
  applyMiddleware(
    thunkMiddleware // lets us dispatch() functions
  )
);

export default store;
