import { Ref } from 'redux-orm';
import { createSelector } from 'reselect';
import { getTipData } from '../TipTransactionModel/TipTransactionSelector';
import { SparkFilterTable } from './SparkFilterModel';
import { SparkJobStatsTable } from './SparkJobStatsModel';


export interface SparkFilters {
  [key: string]: boolean;
}

export interface IProps {
  // @ts-ignore
  pipeLineData: Ref<SparkJobStatsTable>[]// SparkJobStatsTableEntry[];
  // @ts-ignore
  filters: Ref<SparkFilterTable>[]// SparkJobFilterTableFields[];
}

const getSparkStats = (ownProps: IProps) => ownProps.pipeLineData.filter(val => { return val.Application !== undefined && val.TableOrTopic !== undefined });


export const getEdgeFilters = (ownProps: IProps) => {
  const filters = ownProps.filters.filter(filter => filter.isEdge).reduce((obj, filter) => {
    return {
      ...obj,
      [filter.filterName]: filter.filtervalue
    }
  }, {} as SparkFilters)
  return filters;
};

export const getNodeFilters = (ownProps: IProps) => {
  const filters = ownProps.filters.filter(filter => !filter.isEdge).reduce((obj, filter) => {
    return {
      ...obj,
      [filter.filterName]: filter.filtervalue
    }
  }, {} as SparkFilters)
  return filters;
};



export const applyNodeFilter = createSelector(
  [getSparkStats, getNodeFilters],
  (items, filters) => {
    const returnData = items.filter(val => {
      if (filters[val.Application] || filters[val.TableOrTopic]) {
        return false;
      } else {
        return true;
      }
    });
    return returnData;
  }
)

export const applyEdgeFilter = createSelector(
  [applyNodeFilter, getEdgeFilters],
  (items, filters) => {
    const returnData = items.filter(val => {
      if (filters[val.DataSourceOrSink]) {
        return false;
      } else {
        return true;
      }
    });
    return returnData;
  }
)

export const appendTipData = createSelector(
  [applyEdgeFilter, getTipData],
  (pipeLineData, tipData) => {
    const appendTipData = pipeLineData.map(val => {
      const tempTipData = tipData.filter(tip => tip.Application == val.Application && val.TableOrTopic == tip.TableOrTopic)
      return {
        ...val,
        tipData:tempTipData
      }
    });
    return appendTipData
  }
)

export const getSparkParsedData = createSelector(
  [appendTipData],
  (appendTipData) => {
    return { hashTable: Object.assign({}, appendTipData) };

  }
);