import React from 'react';
import moment, { Moment } from 'moment';
import { Profiler } from "react";

// Fabric UI
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { Pivot, PivotItem } from 'office-ui-fabric-react/lib/Pivot';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';

// Components
import { onRenderCallback } from "../../profiler/ComponentsSpeed";

//Store
import { pullDataMethod } from '../../Redux/StateModel/DetectionPageModel/DetectionPageModel';
import { paramsInterface, argumentFromUrl } from '../OCEDashBoard/OCEDashBoard';
import DetectionPageContainer from '../../containers/DetectionPageContainer';
import { DetectionListConstant, Pages } from '../../Redux/Constants/GeneralConstants';
import { getDetectionConstant } from '../../Redux/Constants/DetectionListConstants';
import { IMsalContext, MsalContext, withMsal, WithMsalProps } from '@azure/msal-react';
import LoadQueryFromCodeContainer from '../../containers/LoadQueryFromCodeContainer';


export interface urlLinks {
  EntityDetails: string;
}

export interface IProps {
  urlExtension: paramsInterface;
  urlParameters: argumentFromUrl;
  thunkLoadDetectionSummary: (contextType: IMsalContext) => void;
  thunkLoadDetectionsApiCall: (
    msalContext: IMsalContext,
    startTime: Moment,
    stopTime: Moment,
    extraParameters: string,
    detectionListConstant: DetectionListConstant,
    pullDataMethod: pullDataMethod) => void;
}

type localState = {
  additionalDataisClicked: boolean,
  itemPerPageButtonClicked: boolean,
  elementsPerPage: number,
}

const _getKeyValue_ = (key: string) => (obj: Record<string, any>) => obj[key];


export class TriageAlerts extends React.Component<IProps & WithMsalProps, localState>{

  readonly state: localState = {
    additionalDataisClicked: false,
    itemPerPageButtonClicked: false,
    elementsPerPage: 1000,
  }


  constructor(cProps: IProps & WithMsalProps) {
    super(cProps);

  }

  componentDidMount() {

  }

  public render(): JSX.Element {
    return (
      <Fabric className="OCEDashBoard">
        <Pivot>
          <PivotItem headerText="Detection History" className="OCEDashBoard--PivotItem">
            <LoadQueryFromCodeContainer 
                urlParameters={this.props.urlParameters}
                msalContext={this.props.msalContext}
            />
          </PivotItem>
        </Pivot>
      </Fabric>
    );
  }
}

export default withMsal(TriageAlerts);
