import React from 'react';
import ReactLoading from "react-loading";

// Fabric UI
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';

// Containers
import DatePickerContainer from '../../containers/DatePickerContainer';

// Store
import { argumentFromUrl } from '../OCEDashBoard/OCEDashBoard';
import { DetectionPageTable } from '../../Redux/StateModel/DetectionPageModel/DetectionPageModel';
import { Ref } from 'redux-orm';
import DetectionListPopupWindowContainer from '../../containers/DetectionListPopupWindowContainer';
import DetectionListContainer from '../../containers/DetectionListContainer';
import { engHubReadMe, kustoHunting } from '../../vanquishConfig';
import { DetectionListConstant } from '../../Redux/Constants/GeneralConstants';
import { withMsal, WithMsalProps } from '@azure/msal-react';


type IProps = {
  visibleItems: any[];
  urlParameters: argumentFromUrl;
  detectionListConstant: DetectionListConstant;
  // @ts-ignore
  detectionPageInfo: Ref<DetectionPageTable>;
}

type localState = {
  rowObject: any;
  showFilters: boolean;
  detectionListPopupWindowContainer: JSX.Element;
  isClicked: boolean;
  timeWindowIsClicked: boolean;
  selectedItemChanged: boolean;
}


class DetectionPage extends React.Component<IProps & WithMsalProps, localState>
{

  readonly state: localState = {
    rowObject: [],
    showFilters: false,
    detectionListPopupWindowContainer: (<b></b>),
    isClicked: false,
    timeWindowIsClicked: false,
    selectedItemChanged: false
  };



  private getDetectionListPopupWindowContainer() {

    if (this.state.showFilters)
      return (
        <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg2 ms-slideLeftIn10">
          <DetectionListPopupWindowContainer
            detectionPageData={this.props.detectionListConstant}
          />
          {/*<DetectionScoringFactors 
            disabled={isScoringFactorsEnabled}
            detectionScoringFactors={this.props.scoringFactor}
          ></DetectionScoringFactors>*/}
        </div>);
    else
      return (<b></b>);
  }

  private getDateSelectorPopupWindowContainer() {
    return (<DatePickerContainer
      detectionListConstant={this.props.detectionListConstant}
      msalContext={this.props.msalContext} />);

  }



  public render(): JSX.Element {

    if (this.props.detectionPageInfo == undefined) {
      return (<br></br>)
    } else if (this.props.detectionPageInfo == undefined || this.props.detectionPageInfo.loadingDetectionListItem) {
      return (<ReactLoading type={"bars"} color={"black"} />);
    } else if (this.props.detectionPageInfo.pageErrorString.message == "") {
      return (

        <Fabric className="DetectionList">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg2 showSummaryDiv">
                <DefaultButton
                  onClick={() => window.open(engHubReadMe, '_blank')}
                  text={"Help"}
                />
                <DefaultButton
                  onClick={() => window.open(kustoHunting, '_blank')}
                  text={"Kusto Hunting"}
                />
              <DefaultButton
                toggle
                checked={this.state.isClicked}
                text={this.state.isClicked ? 'Hide Summary' : 'Show Summary'}
                onClick={() => { this.setState({ isClicked: !this.state.isClicked, showFilters: !this.state.showFilters }) }}>
              </DefaultButton>

            </div>

          </div>
          {this.getDateSelectorPopupWindowContainer()}
          <div className="ms-Grid-row">
            <div className={this.state.showFilters ? "ms-Grid-col ms-sm6 ms-md8 ms-lg10" : ""}>

              <DetectionListContainer
                detectionListConstant={this.props.detectionListConstant}
              />
            </div>
            {this.getDetectionListPopupWindowContainer()}
          </div>
        </Fabric>

      );
    } else {
      return(<div><br></br><b>{this.props.detectionPageInfo.pageErrorString.message}</b></div>);
    }
  }
}


export default withMsal(DetectionPage);

