import * as React from 'react';
import moment, { Moment } from 'moment';

// Fabric UI
import { DatePicker, mergeStyleSets, DefaultButton, ActionButton, IIconProps } from 'office-ui-fabric-react';

// Store

// Containers

// Components
import { DetectionPageTable, pullDataMethod } from '../../../Redux/StateModel/DetectionPageModel/DetectionPageModel';
import { Ref } from 'redux-orm';
import DetectionListLabelerContainer from '../../../containers/DetectionListLabelerContainer';
import { DetectionListConstant } from '../../../Redux/Constants/GeneralConstants';
import { IMsalContext, withMsal, WithMsalProps } from '@azure/msal-react';

const statusIcon: IIconProps = { iconName: 'CommentUrgent' };



interface IProps {
  // @ts-ignore
  detectionPageInfo: Ref<DetectionPageTable>;
  detectionListConstant: DetectionListConstant;
  silentThunkLoadDetectionsApiCall: (msalContext: IMsalContext, startTime: Moment, stopTime: Moment, extraURLInfo: string, detectionListConstant: DetectionListConstant, pullDataMethod: pullDataMethod) => void;
  importICMData: any;
}

const controlClass = mergeStyleSets({
  control: {
    margin: '0 0 15px 0',
    maxWidth: '200px',
  },
});

type localState = {
  localStartTime: Moment,
  localStopTime: Moment,
  updateAPIData: boolean,
  isLabelWindowOpen: boolean
};

class DatePickerDetectionPage extends React.Component<IProps & WithMsalProps, localState> {
  timerID?: NodeJS.Timeout;
  readonly state: localState;

  constructor(cProps: IProps & WithMsalProps) {
    super(cProps);
    this.state = { localStartTime: cProps.detectionPageInfo.startTime, localStopTime: cProps.detectionPageInfo.stopTime, updateAPIData: true, isLabelWindowOpen: false };
    this.dateStartChanged = this.dateStartChanged.bind(this);
    this.dateStopChanged = this.dateStopChanged.bind(this);
  }

  private dateStartChanged(date: Date | null | undefined) {
    if (date === null || date === undefined)
      return;
    this.setState({ localStartTime: moment(date).utc().startOf('day') });
  }

  private dateStopChanged(date: Date | null | undefined) {
    if (date === null || date === undefined)
      return;
    this.setState({ localStopTime: moment(date).utc().endOf('day') });
  }

  public loadSetDate() {
    this.props.importICMData(this.props.msalContext, this.state.localStartTime, this.state.localStopTime, "test", this.props.detectionPageInfo.extraURLInfo, this.props.detectionListConstant, pullDataMethod.selectDate);
  }

  public pullCurrentData() {
    this.props.importICMData(this.props.msalContext, this.state.localStartTime, moment(), "test", this.props.detectionPageInfo.extraURLInfo, this.props.detectionListConstant, pullDataMethod.pullLatest);
  }

  public enableAutoUpdate() {
    let startTime = moment(Date.now() - 7 * 24 * 3600 * 1000).startOf('day');
    let stopTime = moment();
    this.props.silentThunkLoadDetectionsApiCall(this.props.msalContext, startTime, stopTime, this.props.detectionPageInfo.extraURLInfo, this.props.detectionListConstant, pullDataMethod.pullLatest);
    this.props.importICMData(this.props.msalContext, startTime, stopTime, "test", this.props.detectionPageInfo.extraURLInfo, this.props.detectionListConstant, pullDataMethod.pullLatest);
  }

  public getCurrentTimeButton(): JSX.Element {
    if (this.props.detectionPageInfo.pullDataMethod === pullDataMethod.pullLatest) {
      return (<DefaultButton
        className={controlClass.control}
        text={this.props.detectionPageInfo.lastUpdated.utc().format("MM/DD/YYYY HH:mm:ss (UTC)")}
        disabled={true}
        onClick={this.pullCurrentData.bind(this)}>
      </DefaultButton>);
    }
    return (<b></b>);
  }

  public openLabeler(): JSX.Element {
    return (<DetectionListLabelerContainer
      detectionListConstant={this.props.detectionListConstant}
      isVisibleProps={this.state.isLabelWindowOpen}
      msalContext={this.props.msalContext} />);
  }

  public getDateController(): JSX.Element {
    if(this.props.detectionPageInfo.pullDataMethod != pullDataMethod.query)
    {
      return (<table><tbody>
        <tr><td>
          <ActionButton iconProps={statusIcon} onClick={() => this.setState({ isLabelWindowOpen: !this.state.isLabelWindowOpen })}>
            Label Detection
          </ActionButton>
        </td><td>
            <DatePicker
              className={controlClass.control}
              value={this.state.localStartTime.toDate()}
              onSelectDate={this.dateStartChanged}
              placeholder={"Select a start date"}
              ariaLabel="Select a start date"
              disabled={this.props.detectionPageInfo.pageURL === "FakeURL"}
            />
          </td><td>
            <DatePicker
              className={controlClass.control}
              value={this.state.localStopTime.toDate()}
              onSelectDate={this.dateStopChanged}
              placeholder={"Select a stop date"}
              ariaLabel="Select a stop date"
              disabled={this.props.detectionPageInfo.pageURL === "FakeURL"}
            />
          </td><td>
            <DefaultButton
              className={controlClass.control}
              text={'ChangeTime'}
              disabled={this.props.detectionPageInfo.pageURL === "FakeURL"}
              onClick={this.loadSetDate.bind(this)}>
            </DefaultButton>
          </td><td>
            <DefaultButton
              className={controlClass.control}
              text={"Pull Latest Data"}
              disabled={this.props.detectionPageInfo.pageURL === "FakeURL"}
              onClick={this.pullCurrentData.bind(this)}>
            </DefaultButton>
            
          </td><td>
            {this.getCurrentTimeButton()}
            {this.openLabeler()}
          </td></tr></tbody></table>)
    }else {
      return (<table><tbody>
        <tr><td>
          <ActionButton iconProps={statusIcon} onClick={() => this.setState({ isLabelWindowOpen: !this.state.isLabelWindowOpen })}>
            Label Detection
          </ActionButton>
        </td><td>
            {this.getCurrentTimeButton()}
            {this.openLabeler()}
          </td></tr></tbody></table>)
    }
  }

  public render(): JSX.Element {
    return (
      <div>
      {this.getDateController()}
      </div>
    );
  }
}

export default withMsal(DatePickerDetectionPage);


