import { attr, Model, ModelType } from 'redux-orm';
import * as actions from "../../Constants/Actions";

// Interfaces
export interface EngagementInfoTableFields {
  serviceEntityType: string;
  serviceEntityName: string;
  serviceTreeId: string;
  icmTeams: Object;
  engagementNotes: string;
  serviceTreeDL: {ServiceTeamDL: string};
  serviceTreeIcMTenantTeam: {TenantId: number, TeamId: number, TenantName: string, TeamName: string};
}

// Action types
export interface AddAllEngagementInfoAction {
  type: typeof actions.ADD_ENGAGEMENT_INFO;
  engagementInfoList: EngagementInfoTableFields[];
}

export interface GetEngagementInfoAction {
  type: typeof actions.GET_ENGAGEMENT_INFO;
  engagementInfo: EngagementInfoTableFields;
}

export interface UpdateEngagementInfoAction {
  type: typeof actions.UPDATE_ENGAGEMENT_INFO;
  newEngagementInfo: EngagementInfoTableFields;
}

export type EngagementInfoModelActionTypes = AddAllEngagementInfoAction | UpdateEngagementInfoAction | GetEngagementInfoAction;

// Action Functions
export function addEngagementInfo(engagementInfoList: EngagementInfoTableFields[]): EngagementInfoModelActionTypes {
  return {
    type: actions.ADD_ENGAGEMENT_INFO,
    engagementInfoList
  };
}

export function updateEngagementInfo(newEngagementInfo: EngagementInfoTableFields): EngagementInfoModelActionTypes {
  return {
    type: actions.UPDATE_ENGAGEMENT_INFO,
    newEngagementInfo
  };
}

// @ts-ignore
export class EngagementInfoTable extends Model<typeof EngagementInfoTable, EngagementInfoTableFields> {
  static modelName = 'EngagementInfoTable' as const;
  
  static fields = {
    serviceEntityType: attr(),
    serviceEntityName: attr(),
    serviceTreeId: attr(),
    icmTeams: attr(),
    engagementNotes: attr(),
    serviceTreeDL: attr(),
    serviceTreeIcMTenantTeam: attr()
  };

  static options = {
    idAttribute: 'serviceEntityName' as const
  };

  // @ts-ignore
  static reducer(action: EngagementInfoModelActionTypes, engagementInfoTable: ModelType<EngagementInfoTable>) {
    switch (action.type) {
      case actions.ADD_ENGAGEMENT_INFO:
        action.engagementInfoList.forEach(val => {
          engagementInfoTable.create(val);
        })
        break;
      
        case actions.UPDATE_ENGAGEMENT_INFO:
          const newInfo = action.newEngagementInfo;
          // action.newEngagementInfo
          const tableEntry = engagementInfoTable.all().filter(info => info.serviceEntityName === newInfo.serviceEntityName && 
            info.serviceEntityType === newInfo.serviceEntityType).toModelArray();
          // there should only be a unique entry
          if(tableEntry.length > 0 ){
            tableEntry.map(info => info.update(newInfo));
          }else{
            engagementInfoTable.create(newInfo);
          }
          break;

      default:
          break;
    }
  }
}