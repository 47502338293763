// Fabric UI
import { useMsal } from '@azure/msal-react';
import { initializeIcons } from '@uifabric/icons';
import qs from 'qs';
import React from 'react';
// Components
import OCEDashBoardContainer from '../../containers/OCEDashBoardContainer';
import TriageAlertsContainer from '../../containers/TriageAlertsContainer';
import { argumentFromUrl } from '../OCEDashBoard/OCEDashBoard';



interface locationInterface {
  search: string;
}

export interface IProps {
  //this is part of the url before the ? such as google.com/text?a="Hello", match would contain an object with "text" in it
  match: any;
  //this is part of the url after the ? such as google.com/text?a="Hello", location would contain an object with "a="Hello"" in it
  location: locationInterface;
}



export const StartApp = (props: IProps): JSX.Element => {
  initializeIcons();
  return (
    <div>
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-lg12">
            <OCEDashBoardContainer msalContext={useMsal()} urlExtension={props.match} urlParameters={qs.parse(props.location.search, { ignoreQueryPrefix: true }) as unknown as argumentFromUrl} />
          </div>
        </div>
      </div>
    </div>
  );
}

export const TriageAlerts = (props: IProps): JSX.Element => {
  initializeIcons();
  return (
    <div>
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-lg12">
            <TriageAlertsContainer msalContext={useMsal()} urlExtension={props.match} urlParameters={qs.parse(props.location.search, { ignoreQueryPrefix: true }) as unknown as argumentFromUrl} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default StartApp;
