import { createSelector } from 'reselect';
import { TipTransactionTableFields } from './TipTransactionModel';

export interface SparkFilters {
  [key: string]: boolean;
}

export interface IProps {
  tipJobData: TipTransactionTableFields[];
}

export const getTipData = (ownProps: IProps) => ownProps.tipJobData;

export const getTipList = createSelector(
  [getTipData],
  (tipData) => {
    const tipList:string[] = Array.from(new Set(tipData.map(val => ("TipEvent:").concat(val.TipName))))
    return tipList;
  })
