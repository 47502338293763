import { TableState } from "redux-orm";
import { createSelector } from "reselect";
import { DetectionSummaryTable, DetectionSummaryTableField } from "./DetectionSummaryModel";

export interface IProps {
    // @ts-ignore
    detectionSummaryTable: TableState<typeof DetectionSummaryTable>;
}

export interface DetectionType {
    detectionType: string;
}

export function generateDescription(item: any, detectionSummary: DetectionSummaryTableField | undefined) {
    if (detectionSummary === undefined) {
        return "";
    }

    //Match strings with "" around them
    return detectionSummary.Description.replace(/"[a-z,A-Z]*"/g, (match) => {
        //remove the "" to get the string back
        const parameter = match.substring(1, match.length - 1);
        return item.additional_data[parameter];
    })
}

// @ts-ignore
export const getDetectionSummaries = (props: IProps):  TableState<typeof DetectionSummaryTable> => {
    return props.detectionSummaryTable;
}