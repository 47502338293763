import React from 'react';

// Fab
import { DetailsList, DetailsListLayoutMode } from 'office-ui-fabric-react/lib/DetailsList';
import { Selection } from 'office-ui-fabric-react/lib/Selection';

// Store
import { DetectionSelectionInfo, DetectionTable, DetectionTableFields } from '../../Redux/StateModel/DetectionModel/DetectionModel';
import { SelectedDetections } from '../../Redux/StateModel/DetectionModel/DetectionSelector';
//import { Pagination } from '@uifabric/experiments';
import { DetectionPageTable, DetectionPageTableFields } from '../../Redux/StateModel/DetectionPageModel/DetectionPageModel';

import { Ref } from 'redux-orm';
import { DetectionListColumns, DetectionListConstant } from '../../Redux/Constants/GeneralConstants';

type IProps = {
  detectionListConstant: DetectionListConstant;
  // @ts-ignore
  detectionPageInfo: Ref<DetectionPageTable>;
  // @ts-ignore
  visibleItems: Ref<DetectionTable>[];
  detectionCount: number;
  selectDetectionData: (selectionChange: DetectionSelectionInfo[]) => any;
  selectedDetections: SelectedDetections;
  updatePage: (newPage: DetectionPageTableFields) => any;
}

type localState = {
}

class DetectionList extends React.Component<IProps, localState>
{

  readonly state: localState = {
  };

  readonly selection = new Selection({
    // @ts-ignore
    getKey: (item: Ref<DetectionTable>) => item.uniqueId,
    onSelectionChanged: () => this.onSelectionChanged(),
  })

  constructor(cProps: IProps) {
    super(cProps);
  }

  //Helper functions----------------------------------------------
  private getDiffSelectionChange(newSelection: SelectedDetections, oldSelection: SelectedDetections): DetectionSelectionInfo[] {
    const diffInANotInB = (a: SelectedDetections, b: SelectedDetections): DetectionTableFields[] => {
      return Object.keys(a).filter(key => b[key] == null).map(key => a[key])
    }

    const selected: DetectionSelectionInfo[] = diffInANotInB(newSelection, oldSelection).map(val => { return { uniqueIds: val.uniqueId, isSelected: true } });
    const unSelected: DetectionSelectionInfo[] = diffInANotInB(oldSelection, newSelection).map(val => { return { uniqueIds: val.uniqueId, isSelected: false } });

    return selected.concat(unSelected)
  }

  private onSelectionChanged(): void {
    let selectedDetections: SelectedDetections = {};
    for (let i = 0; i < this.selection.getSelectedCount(); i++) {
      const fullObj = this.selection.getSelection()[i] as DetectionTableFields;
      selectedDetections[fullObj.uniqueId] = fullObj
    }

    const updates: DetectionSelectionInfo[] = this.getDiffSelectionChange(selectedDetections, this.props.selectedDetections)
    if (updates.length > 0)
      this.props.selectDetectionData(updates);

  }

  private initializeSelectionObject(): void {
    this.selection.setChangeEvents(false);
    this.selection.setItems(this.props.visibleItems)

    Object.values(this.props.selectedDetections).forEach(detection => {
      this.selection.setKeySelected(detection.uniqueId, true, false);
    })
    this.selection.setChangeEvents(true);
  }

  //----------------------------------------------------------------
  private getColumns(): DetectionListColumns[] {
    if(this.props.detectionPageInfo.detectionColumns.length != 0)
    {
      return this.props.detectionPageInfo.detectionColumns.map(val => {
        return { key: val, name: val, fieldName: val, minWidth: 100, maxWidth: 200, isResizable: true }
      })
    }
    return this.props.detectionListConstant.detectionColumns;
  }

  public render(): JSX.Element {
    this.initializeSelectionObject();

    return (
      <div>
        <DetailsList
          setKey={'banana'}
          getKey={item => item.uniqueId}
          items={this.props.visibleItems}
          columns={this.getColumns()}
          onRenderItemColumn={this.props.detectionListConstant.renderFunction}
          onRenderRow={this.props.detectionListConstant.rowRenderFunction}
          layoutMode={DetailsListLayoutMode.justified}
          selection={this.selection}
          selectionPreservedOnEmptyClick={true}
          ariaLabelForSelectionColumn="Toggle selection"
          ariaLabelForSelectAllCheckbox="Toggle selection for all items"
          checkButtonAriaLabel="Row checkbox"
          styles={{
            headerWrapper: {
              marginTop: "-20px"
            }
          }}
        />
      </div>

    )

  }
}

export default DetectionList;

