import moment from 'moment';
import { Moment } from 'moment';
import { attr, Model, ModelType } from 'redux-orm'
import * as actions from '../../Constants/Actions'

export enum pullDataMethod {
    link,
    pullLatest,
    selectDate,
    query
}

export interface DetectionPageTableUpdate {
    pageName: string;
    loadingDetectionListItem: boolean;
    pageErrorString?: errorMessage;
    detectionColumns? :string[];
    startTime: Moment;
    stopTime: Moment;
    pageURL: string;
    extraURLInfo: string;
    pullDataMethod: pullDataMethod;
}

export interface errorMessage {
    message: string;
    stack: string;
}

export interface DetectionPageTableFields {
    pageName: string;
    loadingDetectionListItem: boolean;
    pageErrorString: errorMessage;
    detectionColumns: string[];
    startTime: Moment;
    stopTime: Moment;
    pageURL: string;
    extraURLInfo: string;
    pullDataMethod: pullDataMethod;
    lastUpdated: Moment;
    pageIndex: number;
}

export interface AddDetectionPageItemsAction {
    type: typeof actions.ADD_DETECTIONS_PAGE;
    detectionData: DetectionPageTableFields;
}

interface ChangePageIndexAction {
    type: typeof actions.UPDATE_PAGE;
    newPage: DetectionPageTableFields;
}

export type DetectionPageDataModelActionTypes = AddDetectionPageItemsAction | ChangePageIndexAction


export interface KustoColum {
    ColumnName: string;
    ColumnType: string;
  }
  
  export interface KustoAPIObject {
    FrameType: string;
    TableId?: number;
    TableName?: string;
    TableKind? : string;
    Columns?: KustoColum[];
    Rows? : any[][]
    [extraField: string]: any;
  }
  
  export interface dataObject {
    [extraField: string]: any;
  }

//Action Functions
export function addDetectionPageData(detectionInput: DetectionPageTableUpdate): DetectionPageDataModelActionTypes {
    const currTime: Moment = moment();
    const error: errorMessage = detectionInput.pageErrorString != null ? detectionInput.pageErrorString : {message:"",stack:""}
    const detectionColumns: string[] = detectionInput.detectionColumns != null ? detectionInput.detectionColumns : []

    const detectionData: DetectionPageTableFields = {
        ...detectionInput,
        lastUpdated: currTime,
        pageErrorString: error,
        detectionColumns:detectionColumns,
        pageIndex: 0
    }
    return {
        type: actions.ADD_DETECTIONS_PAGE,
        detectionData,
    }
}

export function updatePage(newPage: DetectionPageTableFields): DetectionPageDataModelActionTypes {
    return {
        type: actions.UPDATE_PAGE,
        newPage
    }
}

// @ts-ignore
export class DetectionPageTable extends Model<typeof DetectionPageTable, DetectionPageTableFields> {
    static modelName = 'DetectionPageTable' as const;
    static fields = {
        loadingDetectionListItem: attr(),
        pageErrorString: attr(),
        detectionColumns: attr(),
        startTime: attr(),
        stopTime: attr(),
        pageURL: attr(),
        extraURLInfo: attr(),
        pullDataMethod: attr(),
        lastUpdated: attr(),
        pageIndex: attr()
    };

    static options = {
        idAttribute: 'pageName' as const
    };

    // @ts-ignore
    static reducer(action: DetectionPageDataModelActionTypes, detectionPageTable: ModelType<DetectionPageTable>) {
        switch (action.type) {
            case actions.ADD_DETECTIONS_PAGE:
                //Append detection owner to the object and insert it into the table
                const detection2 = detectionPageTable.withId(action.detectionData.pageName);
                if (detection2 != null)
                    detection2.update(action.detectionData)
                else
                    detectionPageTable.create(action.detectionData);
                break;
            case actions.UPDATE_PAGE:
                const detection = detectionPageTable.withId(action.newPage.pageName);
                if (detection != null)
                    detection.update(action.newPage)
                else
                    detectionPageTable.create(action.newPage);
                break;
            default:
                break;
        }
    }
}
