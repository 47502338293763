import { DetailsList, DetailsListLayoutMode, Fabric, IColumn, IDetailsListStyles, Link, mergeStyles, mergeStyleSets, SelectionMode } from 'office-ui-fabric-react';
// Fabric UI
import React from 'react';
import { DetectionTableFields } from '../../Redux/StateModel/DetectionModel/DetectionModel';


export interface IProps {
  incidentLists: DetectionTableFields[];
}

export interface WorkloadIcMHistoryState {
  // state of list
  columns: IColumn[];
}


// representing information we want to render for an incident
export interface IIncident {
  key: string;
  name: string;
  value: string;
  modifiedBy: string;
  dateModified: string;
  dateModifiedValue: number;
}

export class WorkloadIcmHistory extends React.Component<IProps, WorkloadIcMHistoryState> {

  constructor(props: IProps) {
    super(props);

    const columns: IColumn[] = [
      {
        key: 'icmTeamColumn',
        name: 'Owning Team',
        fieldName: 'owningTeam',
        minWidth: 210,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        ariaLabel: 'Column operations for File type, Press to sort on File type',
        data: 'string',
        onRender: (item: DetectionTableFields) => {
          return <span>{item.OwningTeamName} </span>;
        },
      },
      {
        key: 'detectionDateColumn',
        name: 'Created Date',
        fieldName: 'date_time_utc',
        minWidth: 100,
        maxWidth: 120,
        isResizable: true,
        isSorted: true,
        data: 'number',
        onRender: (item: DetectionTableFields) => {
          return <span>{item.date_time_utc}</span>;
        },
        isPadded: true,
      },
      {
        key: 'incidentIdColumn',
        name: 'Incident ID',
        fieldName: 'IncidentId',
        minWidth: 100,
        maxWidth: 120,
        isRowHeader: true,
        isResizable: true,
        onRender: (item: any) => {
          const ticketNumber = item["IncidentId" as keyof any] as string;
          let icmLink = "https://portal.microsofticm.com/imp/v3/incidents/details/" + ticketNumber + "/home";
          return (<Link onClick={() => window.open(icmLink, '_blank')} style={{ overflowWrap: 'break-word', whiteSpace: 'break-spaces' }}>{ticketNumber}</Link>);
        },
      },
      {
        key: 'detectionNameColumn',
        name: 'Detection Name',
        fieldName: 'Name',
        minWidth: 210,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        data: 'string',
        onRender: (item: DetectionTableFields) => {
          return <span>{item.name}</span>;
        },
        isPadded: true,
      }
    ];

    this.state = {
      columns: columns,
    };
  }

  public render() {
    const { columns } = this.state;
    const items = this.props.incidentLists;

    return (
      <Fabric>
        <DetailsList
          items={items}
          compact={false}
          columns={columns}
          selectionMode={SelectionMode.none}
          getKey={this._getKey}
          setKey="none"
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
        />
      </Fabric>
    );
  };

  private _getKey(item: any, index?: number): string {
    return item.key;
  };
}
