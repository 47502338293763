import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

// Store
import { AppState } from '../Redux';
import { thunkSaveEngagementInfoChanges } from '../Redux/ApiCalls/EngagementInfoActions';
import { getAllDetectionsWithSimpleIcMData } from '../Redux/StateModel/DetectionModel/DetectionSelector';
import { thunkLoadAllEngagementInfo } from '../Redux/ApiCalls/EngagementInfoActions';

// Components
import WorkloadInfoList from '../components/WorkloadInfo/WorkloadInfoList';
import { EngagementInfoTableFields } from '../Redux/StateModel/EngagementInfoModel/EngagementInfoModel';
import { IMsalContext } from '@azure/msal-react';

const mapStateToProps = (state: AppState) => {
  return {
    workloads: Object.values(state.orm.WorkloadTable.itemsById),
    engagementInfo: Object.values(state.orm.EngagementInfoTable.itemsById),
    detectionsWithICMData: getAllDetectionsWithSimpleIcMData({
      detectionTable: state.orm.DetectionTable,
      icmTable: state.orm.ICMTable,
    })
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
    saveEngagementInfoChanges: (contextType: IMsalContext, newEngagementInfo: EngagementInfoTableFields) => {
      return dispatch(thunkSaveEngagementInfoChanges(contextType, newEngagementInfo));
    },
    thunkLoadEngagementInfo: (contextType: IMsalContext) => dispatch(thunkLoadAllEngagementInfo(contextType))
  }
}

const WorkloadInfoListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkloadInfoList);

export default WorkloadInfoListContainer;
