import { IBasePicker, ITag } from 'office-ui-fabric-react';
// Fabric UI
import React from 'react';
import ReactLoading from "react-loading";
import { Ref } from 'redux-orm';
import { DetectionTableFields } from '../../Redux/StateModel/DetectionModel/DetectionModel';
import { EngagementInfoTable, EngagementInfoTableFields } from '../../Redux/StateModel/EngagementInfoModel/EngagementInfoModel';
import { WorkloadTable } from '../../Redux/StateModel/WorkloadModel/WorkloadModel';
import { WorkloadPicker } from '../WorkloadFilter/WorkloadPicker';
import { WorkloadInfo } from './WorkloadInfo';
import { withRouter, RouteComponentProps } from "react-router-dom";
import { IMsalContext, withMsal, WithMsalProps } from '@azure/msal-react';

export interface IProps {
  // @ts-ignore
  workloads: Ref<WorkloadTable>[];
  // @ts-ignore
  engagementInfo: Ref<EngagementInfoTable>[];
  detectionsWithICMData: DetectionTableFields[];
  saveEngagementInfoChanges: (contextType: IMsalContext, newEngagementInfo: EngagementInfoTableFields) => Promise<any>;
  thunkLoadEngagementInfo: (contextType: IMsalContext) => Promise<void>
}

class WorkloadInfoList extends React.Component<IProps & RouteComponentProps & WithMsalProps> {
  // component references
  private _picker = React.createRef<IBasePicker<ITag>>();

  componentDidMount() {
    this.props.thunkLoadEngagementInfo(this.props.msalContext);
  }

  public render() {
    const workloadTagList = this.props.workloads.map(item => ({ key: item.workload, name: item.workload }));
    const urlParams = new URLSearchParams(this.props.location.search)
    const workloadParam = urlParams.get('workload')
    const workloadParamTagMatch = workloadTagList.find(tag => tag.key === workloadParam);
    return (
      <div>
        {
          this.props.workloads.length == 0 ?
            <ReactLoading type={"bars"} color={"black"} /> :
            <WorkloadPicker
              componentRef={this._picker}
              onChange={this._onChange}
              itemLimit={1}
              itemList={workloadTagList}
              defaultValue={workloadParamTagMatch ? [workloadParamTagMatch] : []}
            />
        }
        {workloadParamTagMatch !== undefined && workloadParam !== null ?
          this.renderWorkloadInfo(workloadParam)
          : null
        }
      </div>

    );
  }

  // listens to changes in the workload that is selected and sets the state as appropriate based on the selection
  private _onChange = (items?: ITag[] | undefined): void => {
    const urlParams = new URLSearchParams(this.props.location.search)
    const workloadParam = urlParams.get('workload')
    if (items === undefined) return;
    if (items.length == 0) {
      this.props.history.push({
        search: ''
      });
    } else if (items.length == 1 && items[0].name !== workloadParam) {
      let currentlySelectedWorkload = items[0].name;
      this.props.history.push({
        search: `?workload=${currentlySelectedWorkload}`
      });
    }
  }

  // @ts-ignore
  private createEngagementInfoTableEntry(workloadName: string, engagementInfo?: Ref<EngagementInfoTable>): EngagementInfoTableFields {
    if (typeof engagementInfo !== 'undefined') {
      return {
        serviceEntityType: engagementInfo.serviceEntityType,
        serviceEntityName: workloadName,
        serviceTreeId: engagementInfo.serviceTreeId,
        icmTeams: engagementInfo.icmTeams,
        engagementNotes: engagementInfo.engagementNotes,
        serviceTreeDL: engagementInfo.serviceTreeDL,
        serviceTreeIcMTenantTeam: engagementInfo.serviceTreeIcMTenantTeam
      }
    } else {
      return {
        serviceEntityType: "Workload",
        serviceEntityName: workloadName,
        serviceTreeId: "",
        icmTeams: {},
        engagementNotes: "",
        serviceTreeDL: { ServiceTeamDL: "" },
        serviceTreeIcMTenantTeam: {TenantId: -1, TeamId: -1, TenantName: "", TeamName: ""}
      }
    }
  }

  // renders the workload information for workload (no set is state)
  private renderWorkloadInfo(workload: string) {
    let workloadInfo = this.props.workloads.filter(w => w.workload == workload);
    let engagementInfoList = this.props.engagementInfo.filter(info => info.serviceEntityName == workload);
    let engagementInfo = engagementInfoList.length == 0 ? this.createEngagementInfoTableEntry(workload) : this.createEngagementInfoTableEntry(workload, engagementInfoList[0]);

    if (workloadInfo.length == 0) {
      return;
    }

    if (engagementInfo) {
      return (<WorkloadInfo
        workloadInfo={workloadInfo[0]}
        engagementInfo={engagementInfo}
        icmHistory={this.props.detectionsWithICMData.filter(d => d.workload === workload)}
        saveEngagementInfoChanges={this.props.saveEngagementInfoChanges}
        contextType = {this.props.msalContext}
      />);
    }
  }

}
export default withMsal(withRouter(WorkloadInfoList))