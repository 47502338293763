import { DetectionTableInput } from "../DetectionModel/DetectionModel";
import { ICMTableFields } from "../ICMModel/ICMModel";
import { AddDetectionFilterListItemsAction, DetectionFilterTableField, DetectionFilterTableFields, UpdateDetectionFilterListItemsAction } from "./DetectionFilterInterface";
import * as actions from "../../Constants/Actions";
import { DetectionListConstant, DetectionListConstants, PreSetFilter } from "../../Constants/GeneralConstants";

export type DetectionFilterDataModelActionTypes = AddDetectionFilterListItemsAction | UpdateDetectionFilterListItemsAction

//Helper function
function generateDetectionFilter(detectionFilterOwner: string, filterSubGroup: string, filterName: string, presets: PreSetFilter, removeByDefault:boolean | undefined): DetectionFilterTableField {
    const uniqueIdentifier: string = detectionFilterOwner + "_" + filterSubGroup + "_" + filterName;
    let applied = false;
    let reverse = false;
    
    if(presets[filterName] !== undefined)
    {
        applied = presets[filterName]
    }

    if(removeByDefault !== undefined)
    {
        reverse = removeByDefault;
        applied = !applied;
    }
    
    
    return {
        uniqueIdentifier: uniqueIdentifier,
        detectionFilterOwner: detectionFilterOwner,
        filterSubGroup: filterSubGroup,
        filterName: filterName,
        applied: applied,
        ctrlApplied: reverse
    }

}

const _getKeyValue_ = (key: string) => (obj: Record<string, any>) => obj[key];

export function addIcmDataFilters(icmData: ICMTableFields[], pageFields: DetectionListConstants) {
    let detectionFilterMap: DetectionFilterTableFields = {};
    Object.values(pageFields).forEach((detectionListData: DetectionListConstant) =>
        icmData.forEach((icm: ICMTableFields) => {
            Object.values(detectionListData.detectionFilterLayout).forEach((field) => {
                let newFilter: DetectionFilterTableField;
                if (icm.hasOwnProperty(field.field)) {
                    newFilter = generateDetectionFilter(detectionListData.pageName, field.field, _getKeyValue_(field.field)(icm), field.presets, field.removeByDefault);
                    detectionFilterMap[newFilter.uniqueIdentifier] = newFilter;
                }
            })
        })
    )
    const detectionFilters: DetectionFilterTableField[] = Object.values(detectionFilterMap);
    return {
        type: actions.ADD_DETECTIONS_FILTER,
        detectionFilters
    };
}

//This is the function the user will call
export function addFilters(detections: DetectionTableInput[], pageFields: DetectionListConstant): DetectionFilterDataModelActionTypes {
    let detectionFilterMap: DetectionFilterTableFields = {};

    //Summarize detections by fields
    detections.forEach(detection => {
        Object.values(pageFields.detectionFilterLayout).forEach(field => {
            let newFilter: DetectionFilterTableField;
            if (detection.hasOwnProperty(field.field)) {
                newFilter = generateDetectionFilter(pageFields.pageName, field.field, _getKeyValue_(field.field)(detection), field.presets, field.removeByDefault);
                detectionFilterMap[newFilter.uniqueIdentifier] = newFilter;
            }
            else if (detection.hasOwnProperty("additional_data") && detection.additional_data.hasOwnProperty(field)) {
                newFilter = generateDetectionFilter(pageFields.pageName, field.field, detection.additional_data[field.field], field.presets, field.removeByDefault);
                detectionFilterMap[newFilter.uniqueIdentifier] = newFilter;
            }


        })
    })
    const detectionFilters = Object.values(detectionFilterMap);
    return {
        type: actions.ADD_DETECTIONS_FILTER,
        detectionFilters
    };
}

export function updateFilterClick(event: any, filter: DetectionFilterTableField): DetectionFilterDataModelActionTypes {
    const ctrlClicked = event.nativeEvent.ctrlKey
    return updateFilter({
        ...filter,
        ctrlApplied: ctrlClicked
    })
}

export function updateFilter(filter: DetectionFilterTableField): DetectionFilterDataModelActionTypes {
    return {
        type: actions.UPDATE_DETECTIONS_FILTER,
        filter,
    };
}