import React from 'react';
import CytoscapeComponent from 'react-cytoscapejs';
import { IBasePicker, ITag } from 'office-ui-fabric-react/lib/Pickers';
import cytoscape from 'cytoscape';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import { SparkFilters } from '../../Redux/StateModel/PipeLineTopologyModel/PipelineTopologySelector';
import { SparkJobStatsWithTip } from '../../Redux/StateModel/PipeLineTopologyModel/SparkJobStatsModel';
import { WorkloadPicker } from '../WorkloadFilter/WorkloadPicker';
import { TipTransactionTableFields } from '../../Redux/StateModel/TipTransactionModel/TipTransactionModel';
import moment from 'moment';
import { IMsalContext, withMsal, WithMsalProps } from '@azure/msal-react';

interface dataFlowHashTable {
  [title: number]: SparkJobStatsWithTip;
}

export interface dataFlowObject {
  hashTable: dataFlowHashTable;
}

interface IProps {
  input: dataFlowObject
  nodeToEnter: SparkFilters
  edgeToEnter: SparkFilters
  userChangedFilter: any
  getSparkData: (msalContext: IMsalContext) => any
  tipList: string[]
};

type localState = {
  _testTags: ITag[]
  selectedWorkloads: ITag[]
  spacingFactor: number
};

interface IHashSparkJobStats {
  [details: string]: SparkJobStatsWithTip
};

class PipelineTopology extends React.Component<IProps & WithMsalProps, localState>{

  readonly state: localState;

  constructor(cProps: IProps & WithMsalProps) {
    super(cProps);
    this.cyProxy = this.cyProxy.bind(this);
    this.onNodeClicked = this.onNodeClicked.bind(this);
    this.restoreNodes = this.restoreNodes.bind(this);

    const jobs = Object.values(this.props.input.hashTable).map(item => item.Application);
    const uniqueJobs = jobs.filter(function (elem, pos) {
      return jobs.indexOf(elem) === pos;
    });
    const taguniqueJobs = uniqueJobs.map(item => ({ key: item, name: item }));
    this.state = {
      _testTags: taguniqueJobs,
      selectedWorkloads: [],
      spacingFactor: 1.5
    };
  }

  private _picker = React.createRef<IBasePicker<ITag>>();

  private setSelectedItems(setState2: (state: any) => void): ((items?: ITag[]) => void) {
    return (items?: ITag[]) => {
      let values: ITag[] = [];
      if (items !== undefined) {
        values = items as ITag[];
      }
      setState2({ selectedWorkloads: values });
    }
  }

  private _makeChangeHandler = (label: string) => {
    return (ev?: any, checked?: boolean) => this._onCheckboxChange(label, ev, !checked);
  }

  private _onNodeClicked = (label: string, ev?: any, checked?: boolean) => {
    this.props.userChangedFilter(label, false, checked);
  }


  private _onCheckboxChange = (label: string, ev?: any, checked?: boolean) => {
    console.log((checked ? "Checked " : "Unchecked ") + label)
    this.props.userChangedFilter(label, true, checked);
  }

  private findConnectedSparkStat(node: string): SparkJobStatsWithTip[] {
    let newStat: SparkJobStatsWithTip[] = [];
    if (node === "")
      return [];
    Object.values(this.props.input.hashTable).forEach(sparkStat => {
      if (sparkStat.Application === node || sparkStat.TableOrTopic === node) {
        newStat.push(sparkStat);
      }
    })
    return newStat;
  }

  private nodeName(node :SparkJobStatsWithTip){
    return node.Application + "_____" + node.TableOrTopic + "_____" + node.TransactionType
  }

  private mergeSparkNodeSets(set1: SparkJobStatsWithTip[], set2: SparkJobStatsWithTip[]): SparkJobStatsWithTip[] {
    let newSet: IHashSparkJobStats = {};
    set1.forEach(val => {
      newSet[this.nodeName(val)] = val;
    })
    set2.forEach(val => {
      const hash: string = val.Application + "_____" + val.TableOrTopic + "_____" + val.TransactionType;
      newSet[this.nodeName(val)] = val;
    })

    let returnSet: SparkJobStatsWithTip[] = Object.values(newSet);

    return returnSet;
  }

  private bfs(application: string, depth: number): SparkJobStatsWithTip[] {
    if (depth === 0)
      return [];
    let newStat: SparkJobStatsWithTip[] = this.findConnectedSparkStat(application);
    let returnStat: SparkJobStatsWithTip[] = this.findConnectedSparkStat(application);
    newStat.forEach(val => {
      returnStat = this.mergeSparkNodeSets(returnStat, this.bfs(val.Application, depth - 1));
      returnStat = this.mergeSparkNodeSets(returnStat, this.bfs(val.TableOrTopic, depth - 1));
    });
    return returnStat;
  }

  private onNodeClicked(e: cytoscape.EventObject) {
    var ele = e.target.id();
    this._onNodeClicked(ele, false, true);
  }

  private restoreNodes() {
    this.setState({ spacingFactor: 1.5 });
    this._onNodeClicked("RESET_ALL_NODES_PLEASE_KTHX", false, false);
  }

  private changeSpacing(cy: cytoscape.Core, increase: boolean) {
    // unintuitively, increasing the spacing factor decreases the node size
    if (increase && this.state.spacingFactor > 0.5) {
      this.setState({ spacingFactor: this.state.spacingFactor - 0.25 });
    } else {
      this.setState({ spacingFactor: this.state.spacingFactor + 0.25 });
    }
    let layout = cy.layout({ name: 'breadthfirst', directed: true, spacingFactor: this.state.spacingFactor });
    // We need layout.run() to be explicitly called or the node positions will reset after refresh
    layout.run();
  }

  private cyProxy(cy: cytoscape.Core): void {
    let myCyRef = cy;
    myCyRef.nodes().removeListener('click');
    myCyRef.nodes().on('click', this.onNodeClicked);

    document.getElementById("readdButton")!.onclick = this.restoreNodes;
    document.getElementById("tipData")!.onclick = () => this.props.getSparkData(this.props.msalContext);
    document.getElementById("increaseSpacingButton")!.onclick = () => this.changeSpacing(cy, true);
    document.getElementById("decreaseSpacingButton")!.onclick = () => this.changeSpacing(cy, false);

    let layout = myCyRef.layout({ name: 'breadthfirst', directed: true, spacingFactor: 1.5 });
    // We need layout.run() to be explicitly called or the node positions will reset after refresh
    layout.run();

    return;
  }

  private findTipData(tipData: TipTransactionTableFields[], application : string, tableOrTopic: string, TransactionType: string) : TipTransactionTableFields[] {
    var tip: TipTransactionTableFields[] = []
    tipData.forEach(val => {
      if(val.Application == application && val.TableOrTopic == tableOrTopic && val.TransactionType == TransactionType)
      {
        tip = tip.concat(val);
      }
    })
   
    return tip;
  }

  private getLabel(tipData: TipTransactionTableFields[], dataSourceOrSink : string) : string {
    if (tipData.length == 0)
      return dataSourceOrSink;
    var tipText: string = "";
    const selectedTip : string[] = this.state.selectedWorkloads.map(val => val.name.replace("TipEvent:", ""));
    tipData.forEach(val => {
      if(selectedTip.includes(val.TipName))
      {
        const tipName : string = val.TipName != "" ?  " " + val.TipName : "";
        tipText += tipName + ": " + moment().diff(val.Created, 'minute') + " Min,";
      }
    })
    
    if (tipText == "")
      return dataSourceOrSink;
    return tipText; 
  }

  private hasTip(tipName: string, tipData: TipTransactionTableFields[]) : boolean {
    var hasTip = false;
    tipData.forEach(val => {
      if(val.TipName == tipName)
      {
        hasTip = true;
      }
    })
    return hasTip;
  }

  private findTipNodes(tipName: string) : SparkJobStatsWithTip[] {
    return Object.values(this.props.input.hashTable).filter(val => this.hasTip(tipName, val.tipData))
  }

  public render(): JSX.Element {

    const tablesOrTopics = Object.values(this.props.input.hashTable).map(item => item.TableOrTopic);
    const jobs = Object.values(this.props.input.hashTable).map(item => item.Application);
    const uniqueJobs = jobs.filter(function (elem, pos) {

      return jobs.indexOf(elem) === pos;
    });
    const uniqueEh = tablesOrTopics.filter(function (elem, pos) {
      return tablesOrTopics.indexOf(elem) === pos;
    });

    const combinedLookup = uniqueJobs.concat(uniqueEh).concat(this.props.tipList);

    const taguniqueJobs = combinedLookup.map(item => ({ key: item, name: item }));
    if (taguniqueJobs.length !== this.state._testTags.length) {
      this.setState({ _testTags: taguniqueJobs });
    }
    let elements: any = [];

    let displayNodes: SparkJobStatsWithTip[] = [];
    if (this.state.selectedWorkloads.length !== 0) {
      this.state.selectedWorkloads.forEach(val => {
        if(!val.name.startsWith("TipEvent:"))
        {
          displayNodes = this.mergeSparkNodeSets(displayNodes, this.bfs(val.name, 1));
        }else {
          displayNodes = this.mergeSparkNodeSets(displayNodes, this.findTipNodes(val.name.replace("TipEvent:", "")));
        }
      });
    }

    displayNodes.forEach((val: SparkJobStatsWithTip) => {
      if (val.TransactionType !== "") {
        if (val.Application !== "" && val.TableOrTopic !== "") {
          const tipData = this.findTipData(val.tipData, val.Application, val.TableOrTopic, val.TransactionType);
          const label = this.getLabel(tipData,val.DataSourceOrSink);
          if (val.TransactionType === "Read") {
            elements.push({ data: { id: val.Application, label: val.Application } });
            elements.push({ data: { id: val.TableOrTopic, label: val.TableOrTopic } });
            elements.push({ data: { source: val.TableOrTopic, target: val.Application, label: label} });
          } else if (val.TransactionType === "Write") {
            elements.push({ data: { id: val.Application, label: val.Application } });
            elements.push({ data: { id: val.TableOrTopic, label: val.TableOrTopic } });
            elements.push({ data: { source: val.Application, target: val.TableOrTopic, label: label } })
          }
        }
      }
    });

    // Okabe-Ito Colorblind Friendly Palette
    const selectedNodeColor = 'red';
    const azureSqlColor = 'blue';
    const eventHubsColor = '#CC79A7' // Reddish Purple;
    const cosmosDbColor = 'skyblue';
    const parquetColor = 'orange';
    const kustoColor = '#D55E00'; // Vermillion
    const aadEnrichmentProviderColor = '#009E73'; // Bluish Green
    const rawEventHubsColor = 'yellow';

    let nodesToHighlight = this.state.selectedWorkloads.map(val => {
      let selectorEntry = {
        'selector': `node[id="${val.key}"]`,
        'style': {
          'background-color': `${selectedNodeColor}`,
          'line-color': `${selectedNodeColor}`
        }
      }
      return selectorEntry
    });


    return (
      <div>
        <div>Pipeline Topology Explorer</div>
        <div>Choose the jobs, topics, or tables you are interested in with the selector below. Click a node to remove it.</div>

        <WorkloadPicker
          componentRef={this._picker}
          onChange={this.setSelectedItems(this.setState.bind(this))}
          itemLimit={200}
          itemList={this.state._testTags}
        />

        <table>
          <tr>
            <td style={{ width: '10px', height: '10px', background: `${eventHubsColor}` }}></td>
            <td><Checkbox label={"EventHubs"} defaultChecked={!this.props.edgeToEnter["EventHubs"]} onChange={this._makeChangeHandler("EventHubs")} /></td>
            <td style={{ width: '20px' }}></td>

            <td style={{ width: '10px', height: '10px', background: `${rawEventHubsColor}` }}></td>
            <td><Checkbox label={"RawEventHubs"} defaultChecked={!this.props.edgeToEnter["RawEventHubs"]} onChange={this._makeChangeHandler("RawEventHubs")} /></td>
            <td style={{ width: '20px' }}></td>

            <td style={{ width: '10px', height: '10px', background: `${azureSqlColor}` }}></td>
            <td><Checkbox label={"AzureSql"} defaultChecked={!this.props.edgeToEnter["AzureSql"]} onChange={this._makeChangeHandler("AzureSql")} /></td>
            <td style={{ width: '20px' }}></td>

            <td style={{ width: '10px', height: '10px', background: `${cosmosDbColor}` }}></td>
            <td><Checkbox label={"CosmosDb"} defaultChecked={!this.props.edgeToEnter["CosmosDb"]} onChange={this._makeChangeHandler("CosmosDb")} /></td>
            <td style={{ width: '20px' }}></td>

            <td style={{ width: '10px', height: '10px', background: `${parquetColor}` }}></td>
            <td><Checkbox label={"Parquet"} defaultChecked={!this.props.edgeToEnter["Parquet"]} onChange={this._makeChangeHandler("Parquet")} /></td>
            <td style={{ width: '20px' }}></td>

            <td style={{ width: '10px', height: '10px', background: `${kustoColor}` }}></td>
            <td><Checkbox label={"Kusto"} defaultChecked={!this.props.edgeToEnter["Kusto"]} onChange={this._makeChangeHandler("Kusto")} /></td>
            <td style={{ width: '20px' }}></td>

            <td style={{ width: '10px', height: '10px', background: `${aadEnrichmentProviderColor}` }}></td>
            <td><Checkbox label={"AadEnrichmentProvider"} defaultChecked={!this.props.edgeToEnter["AadEnrichmentProvider"]} onChange={this._makeChangeHandler("AadEnrichmentProvider")} /></td>
            <td style={{ width: '20px' }}></td>

            <td><button id="readdButton">Reset View And Nodes</button></td>
            <td><button id="tipData">Pull Latest Tip Data</button></td>
            <td style={{ width: '20px' }}></td>

            <td><button id="increaseSpacingButton">+</button></td>
            <td><button id="decreaseSpacingButton">-</button></td>
          </tr>
        </table>

        <CytoscapeComponent cy={this.cyProxy} elements={elements} style={{ width: '100vw', height: '100vh' }}
          stylesheet={[
            {
              'selector': 'node',
              'style': {
                'content': 'data(label)'
              }
            },
            {
              'selector': 'edge',
              'style': {
                'content': 'data(label)',
                'curve-style': 'bezier',
                'target-arrow-shape': 'vee',
                'arrow-scale': '2',
              }
            },
            {
              'selector': 'edge[label="AzureSql"]',
              'style': {
                'background-color': `${azureSqlColor}`,
                'line-color': `${azureSqlColor}`,
                'target-arrow-color': `${azureSqlColor}`
              }
            },
            {
              'selector': 'edge[label="EventHubs"]',
              'style': {
                'background-color': `${eventHubsColor}`,
                'line-color': `${eventHubsColor}`,
                'target-arrow-color': `${eventHubsColor}`
              }
            },
            {
              'selector': 'edge[label="RawEventHubs"]',
              'style': {
                'background-color': `${rawEventHubsColor}`,
                'line-color': `${rawEventHubsColor}`,
                'target-arrow-color': `${rawEventHubsColor}`
              }
            },
            {
              'selector': 'edge[label="CosmosDb"]',
              'style': {
                'background-color': `${cosmosDbColor}`,
                'line-color': `${cosmosDbColor}`,
                'target-arrow-color': `${cosmosDbColor}`
              }
            },
            {
              'selector': 'edge[label="Parquet"]',
              'style': {
                'background-color': `${parquetColor}`,
                'line-color': `${parquetColor}`,
                'target-arrow-color': `${parquetColor}`
              }
            },
            {
              'selector': 'edge[label="Kusto"]',
              'style': {
                'background-color': `${kustoColor}`,
                'line-color': `${kustoColor}`,
                'target-arrow-color': `${kustoColor}`
              }
            },
            {
              'selector': 'edge[label="AadEnrichmentProvider"]',
              'style': {
                'background-color': `${aadEnrichmentProviderColor}`,
                'line-color': `${aadEnrichmentProviderColor}`
                // cannot add a target arrow color here without crashing.
                // skipping for now.
              }
            },

            {
              'selector': '.triangle',
              'style': {
                'shape': 'triangle'
              }
            }
          ].concat(nodesToHighlight)}
        />
      </div>);
  }
}

export default withMsal(PipelineTopology);
