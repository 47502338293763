import { TableState } from "redux-orm";
import { ICMTable } from "./ICMModel";

export interface icmData {
    // @ts-ignore
    icmTable: TableState<typeof ICMTable>;
}

// @ts-ignore
export const getICMData = (props: icmData) : TableState<typeof ICMTable> => {
    return props.icmTable;
}