import React from 'react';
import moment, { Moment } from 'moment';

// Fabric UI
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { Pivot, PivotItem, PivotLinkFormat } from 'office-ui-fabric-react/lib/Pivot';

// Components


// Store
import DetectionPageContainer from '../../containers/DetectionPageContainer';
import PipelineTopologyContainer from '../../containers/PipelineTopologyContainer';
import WorkloadFilterContainer from '../../containers/WorkloadFilterContainer';
import WorkloadInfoListContainer from '../../containers/WorkloadInfoListContainer';
import { pullDataMethod } from '../../Redux/StateModel/DetectionPageModel/DetectionPageModel';
import { withRouter, Route, Switch, RouteComponentProps } from 'react-router-dom';
import { DetectionListConstant, Pages, Path } from '../../Redux/Constants/GeneralConstants';
import { getDetectionConstant } from '../../Redux/Constants/DetectionListConstants';
import { icmEnabled } from '../../vanquishConfig';
import LoadDetectionFromKustoContainer from '../../containers/LoadDetectionFromKustoContainer';
import { IMsalContext, withMsal, WithMsalProps } from '@azure/msal-react';

export interface urlLinks {
  EntityDetails: string
};

export interface paramsInterface {
  params: urlLinks
};

export interface argumentFromUrl {
  [extraField: string]: any;
};

interface IProps {
  urlExtension: paramsInterface
  urlParameters: argumentFromUrl
  thunkLoadDetectionsApiCall: (msalContext: IMsalContext, startTime: Moment, stopTime: Moment, extraParameters: string, detectionListConstant: DetectionListConstant, pullDataMethod: pullDataMethod) => void
  getSparkData: (msalContext: IMsalContext) => void
  importICMData: (msalContext: IMsalContext, startTime: Moment, stopTime: Moment, owningTeam: string, extraParameters: string, detectionListConstant: DetectionListConstant, pullDataMethod: pullDataMethod) => void
  thunkAddAllWorkloadInfo: (contextType: IMsalContext) => void
};

type localState = {
  selectedKey: string;
};

const _getKeyValue_ = (key: string) => (obj: Record<string, any>) => obj[key];

class OCEDashBoardView extends React.Component<IProps & RouteComponentProps & WithMsalProps, localState>{

  readonly unTriageAlerts: DetectionListConstant = _getKeyValue_(Pages.UnTriageAlerts)(getDetectionConstant());
  readonly activeDetections: DetectionListConstant = _getKeyValue_(Pages.ActiveDetections)(getDetectionConstant());
  readonly labelDetectionHistory: DetectionListConstant = _getKeyValue_(Pages.LabelDetectionHistory)(getDetectionConstant());
  readonly labelThrottledDetectionHistory: DetectionListConstant = _getKeyValue_(Pages.LabelThrottledDetectionHistory)(getDetectionConstant());
  readonly LoadKustoDetections: DetectionListConstant = _getKeyValue_(Pages.LoadKustoDetections)(getDetectionConstant());
  
  constructor(cProps: IProps & RouteComponentProps & WithMsalProps) {
    super(cProps);
    this.state = {
      selectedKey: Pages.UnTriageAlerts
    }
  }

  //On the first mount of this object load all of the data with API calls
  componentDidMount() {
    
    let startTime = moment(this.props.urlParameters.timeRangeStart, moment.ISO_8601, true);
    if (!startTime.isValid())
      startTime = moment(Date.now() - 7 * 24 * 3600 * 1000).startOf('day');
    let stopTime = moment();
    this.props.thunkLoadDetectionsApiCall(this.props.msalContext, startTime, stopTime, "", this.labelThrottledDetectionHistory, pullDataMethod.pullLatest);
    if(icmEnabled)
    {
      this.props.importICMData(this.props.msalContext, startTime, stopTime, "EmptyString", "", this.unTriageAlerts, pullDataMethod.pullLatest);
      this.props.thunkLoadDetectionsApiCall(this.props.msalContext, startTime, stopTime, "", this.activeDetections, pullDataMethod.pullLatest);
      
    }else {
      this.props.thunkLoadDetectionsApiCall(this.props.msalContext, startTime, stopTime, "", this.unTriageAlerts, pullDataMethod.pullLatest);
      this.props.thunkLoadDetectionsApiCall(this.props.msalContext,startTime, stopTime, "", this.activeDetections, pullDataMethod.pullLatest);
    }
    this.props.thunkAddAllWorkloadInfo(this.props.msalContext);
    this.props.getSparkData(this.props.msalContext);
  }

  public render(): JSX.Element {
    return (
      <Fabric className="OCEDashBoard">
        <Switch>
          <Pivot linkFormat={PivotLinkFormat.links}
            onLinkClick={(item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {
              if (item?.props.headerText == "Search workloads info") {
                this.props.history.push({
                  pathname: Path.WorkloadInfos
                })
              } else {
                this.props.history.replace('/')
              }
              if (item) { this.setState({ selectedKey: item.props.itemKey ? item.props.itemKey : Pages.UnTriageAlerts }) }
            }}
            selectedKey={this.props.location.pathname == Path.WorkloadInfos ? Pages.WorkloadInfo : this.state.selectedKey}
          >
            <PivotItem headerText="Un-Triaged Paging" className="OCEDashBoard--PivotItem" itemKey={Pages.UnTriageAlerts}>
              <DetectionPageContainer
                urlParameters={this.props.urlParameters}
                detectionListConstant={this.unTriageAlerts}
                msalContext={this.props.msalContext}
              />
            </PivotItem>
            <PivotItem headerText="Active Detections" className="OCEDashBoard--PivotItem" itemKey={Pages.ActiveDetections}>
              <DetectionPageContainer
                urlParameters={this.props.urlParameters}
                detectionListConstant={this.activeDetections}
                msalContext={this.props.msalContext}
              />
            </PivotItem>
            <PivotItem headerText="Load Detections from Kusto" className="OCEDashBoard--PivotItem" itemKey={Pages.LoadKustoDetections}>
              You must include fields "detection_type", "target_entity", "id", "target_entity_type", "workload" to be able to label
              <LoadDetectionFromKustoContainer 
                urlParameters={this.props.urlParameters}
                detectionListConstant={this.LoadKustoDetections}
                msalContext={this.props.msalContext}
                query=""
            />
            </PivotItem>
            {/*<PivotItem headerText="Throttled Detections" className="OCEDashBoard--PivotItem" itemKey={Pages.LabelThrottledDetectionHistory}>
              <DetectionPageContainer
                urlParameters={this.props.urlParameters}
                detectionListConstant={this.labelThrottledDetectionHistory}
                msalContext={this.props.msalContext}
          />
            </PivotItem>*/}
            <PivotItem headerText="Pipeline Topology" className="OCEDashBoard--PivotItem" itemKey={Pages.PipelineTopology}>
              <PipelineTopologyContainer 
              msalContext={this.props.msalContext} />
            </PivotItem>
            <PivotItem headerText="Find Detections/Label history by workloads" className="OCEDashBoard--PivotItem" itemKey={Pages.LabelDetectionHistory}>
              <WorkloadFilterContainer
                urlLink={this.props.urlExtension.params}
                urlParameters={this.props.urlParameters}
                detectionListConstant={this.labelDetectionHistory} 
                msalContext={this.props.msalContext} />
            </PivotItem>
            <PivotItem headerText="Search workloads info" className="OCEDashBoard--PivotItem" itemKey={Pages.WorkloadInfo}>
              <Route path={Path.WorkloadInfos} component={WorkloadInfoListContainer} />
            </PivotItem>
          </Pivot>
        </Switch>

      </Fabric>
    );
  }
}

export default withRouter((OCEDashBoardView));
