import React from 'react';

// Fabric UI
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import DetectionListFilterContainer from '../../../containers/DetectionListFilterContainer';
import { DetectionFilterLayout, DetectionListConstant } from '../../../Redux/Constants/GeneralConstants';

// Store


export interface IProps {
  detectionPageData: DetectionListConstant,
  filterSubGroup: string[]
}

// Used to add spacing between example checkboxes
const stackTokens = { childrenGap: 5 };

type localState = {
  isQueryDialogOpen: boolean
  isFilterPanelOpen: boolean
  selectStatus: string
  comment: string
};

class DetectionListPopupWindow extends React.Component<IProps, localState>{

  readonly state: localState = {
    isQueryDialogOpen: false,
    isFilterPanelOpen: false,
    selectStatus: "",
    comment: "",
  };

  private dismissQueryDialog(): void {
    this.setState({ selectStatus: "" });
    this.setState({ isQueryDialogOpen: false });
  }

  private generateDetectionListFilterContainer(label: string, filter: string, selectedButtons: boolean): JSX.Element {
    return (
      <DetectionListFilterContainer key={filter} label={label} filter={filter} detectionPageData={this.props.detectionPageData} selectedButton={selectedButtons} />
    );

  }

  private generateFilters(selectedButtons: boolean, pageFilter: DetectionFilterLayout, pageSubGroup: string[])
  {
    if(Object.keys(pageFilter).length == 0)
      return this.getFilterButtonsOnSubGroup(selectedButtons, pageSubGroup)
    else
      return this.getFilterButtons(selectedButtons, pageFilter)
  }

  private getFilterButtons(selectedButtons: boolean, pageFilter: DetectionFilterLayout): JSX.Element {
    return (
      <Fabric>
        {Object.keys(pageFilter).map(value => { return this.generateDetectionListFilterContainer(value, pageFilter[value].field, selectedButtons) })}
      </Fabric>);
  }

  
  private getFilterButtonsOnSubGroup(selectedButtons: boolean, pageFilter: string[]): JSX.Element {
    return (
      <Fabric>
        {pageFilter.map(value => { return this.generateDetectionListFilterContainer(value, value, selectedButtons) })}
      </Fabric>);
  }

  public render(): JSX.Element {

    return (
      <Fabric>
        <Stack className="summaryPopUp" horizontal tokens={stackTokens}>
          {/*<DetectionListResetFiltersButtonContainer detectionState={this.props.detectionState} objName={this.props.objName}/>*/}
        </Stack>

        <Dialog
          hidden={!this.state.isQueryDialogOpen}
          onDismiss={this.dismissQueryDialog}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: 'Search Detections',
            subText: 'This is just a placeholder dialog for now. Eventually this could contain the full search query options from the top of the Detections Dashboard page.'
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <DefaultButton onClick={this.dismissQueryDialog} text="Cancel" />
          </DialogFooter>
        </Dialog>
        {this.generateFilters(true, this.props.detectionPageData.detectionFilterLayout, this.props.filterSubGroup)}
        {this.generateFilters(false, this.props.detectionPageData.detectionFilterLayout, this.props.filterSubGroup)}
      </Fabric>
    );
  }
}

export default DetectionListPopupWindow;


