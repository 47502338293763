import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

// Store
import { AppState } from '../Redux';

// Components
import { getDisplayedFilters } from '../Redux/StateModel/DetectionFilterModel/DetectionFilterSelector';
import { DetectionListConstant } from '../Redux/Constants/GeneralConstants';
import DetectionListFilter from '../components/DetectionList/SupportingObjects/DetectionListFilter';


export interface OwnProps {
  label: string,
  filter: string,
  detectionPageData: DetectionListConstant,
  selectedButton: boolean
};

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  return {
    label: ownProps.label,
    filter: ownProps.filter,
    selectedButton: ownProps.selectedButton,
    filters: getDisplayedFilters({
      detectionListFilterTable: state.orm.DetectionFilterTable,
      detectionTable: state.orm.DetectionTable,
      detectionSummaryTable: state.orm.DetectionSummaryTable,
      icmTable: state.orm.ICMTable,
      detectionOwner: ownProps.detectionPageData.pageName,
      filterSubGroup: ownProps.filter,
    })
  }
}
const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
  }
}



const DetectionListFilterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DetectionListFilter);

export default DetectionListFilterContainer;
