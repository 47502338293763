import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../index';
import { Action } from 'redux';
import { addDetectionSummaryData, DetectionSummaryTableField } from '../StateModel/DetectionSummaryModel/DetectionSummaryModel';
import { IMsalContext } from '@azure/msal-react';
import { addAadTokenToGetRequest } from '../../adal/ApiCall';


// TODO: remove this (moving this to TriageModel for now)
// export function addDetectionInfoSummary(detections: DetectionDescription[]): TriageActionTypes {
//     let detectionInfo: DetectionDescriptions = {}
//     detections.forEach(val => detectionInfo[val.Detection_Type] = val)
//     return {
//         type: ADD_DETECTION_INFO_SUMMARY_TO_STORE,
//         detectionInfo
//     }
// }

export function thunkLoadDetectionSummary(contextType: IMsalContext) {
    return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
        const workloadsLink = "https://vanquish-dal.office.net/c2api/C2Detection/GetDetectionTypes/";
        const apiCallWorkloadsLinkData = (await addAadTokenToGetRequest(contextType, workloadsLink)) as DetectionSummaryTableField[];

        dispatch(addDetectionSummaryData(apiCallWorkloadsLinkData));
    }
}
