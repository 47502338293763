import { IBasePicker, ITag, TagPicker } from 'office-ui-fabric-react';
import React from 'react';

export interface IProps {
  componentRef: React.RefObject<IBasePicker<ITag>>;
  onChange: ((items?: ITag[] | undefined) => void) | undefined;
  itemLimit: number;
  itemList: ITag[];
  defaultValue?: ITag[] | undefined;
  value?: ITag;
}


export class WorkloadPicker extends React.Component<IProps> {

  constructor(props: IProps) {
    super(props);
  }

  public render() {

    return (
      <TagPicker 
        removeButtonAriaLabel="Remove"
        componentRef={this.props.componentRef}
        onResolveSuggestions={this._filterWorkloadTags}
        onChange={this.props.onChange}
        onItemSelected={this._onItemSelected}
        getTextFromItem={this._getTextFromItem}
        pickerSuggestionsProps={{
          suggestionsHeaderText: 'Suggested tags',
          noResultsFoundText: 'No tags found',
        }}
        itemLimit={this.props.itemLimit}
        disabled={false}
        inputProps={{
          'aria-label': 'Tag Picker'
        }}
        defaultSelectedItems={this.props.defaultValue ? this.props.defaultValue : []}
      />
    )
  }

    // functions used in workload tagpicker
    private _filterWorkloadTags = (filterText: string, tagList: ITag[] | undefined): ITag[] => {
      if(tagList === undefined) return [];
      return filterText
        ? this.props.itemList.filter(
          tag => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0 && !this.listContainsTagList(tag, tagList),
        )
        : [];
    };
  
    private listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
      if (!tagList || !tagList.length || tagList.length === 0) {
        return false;
      }
      return tagList.some(compareTag => compareTag.key === tag.key);
    };
  
    private _getTextFromItem = (item: ITag) => item.name;
  
    private _onItemSelected = (selectedItem: ITag | undefined): ITag | null => {
      if (selectedItem === undefined)
        return null;
  
      return selectedItem;
    }
}