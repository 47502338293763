import { attr, Model, ModelType } from 'redux-orm'
import { UPDATE_SPARK_FILTER, UPDATE_SPARK_FILTERS } from '../../Constants/Actions';


export interface SparkJobFilterTableFields {
    filterName: string,
    filtervalue: boolean,
    isEdge: boolean
}

interface UpdateSparkJobFilterItemsAction {
    type: typeof UPDATE_SPARK_FILTER;
    sparkFilter: SparkJobFilterTableFields;
}

interface UpdateSparkJobFiltersItemsAction {
    type: typeof UPDATE_SPARK_FILTERS;
    sparkFilters: SparkJobFilterTableFields[];
}

export type SparkJobFilterModelActionTypes =
    UpdateSparkJobFilterItemsAction |
    UpdateSparkJobFiltersItemsAction

//Action Functions

export function updateSparkFilter(sparkFilter: SparkJobFilterTableFields): SparkJobFilterModelActionTypes {
    return {
        type: UPDATE_SPARK_FILTER,
        sparkFilter,
    }
}

export function updateSparkFilters(sparkFilters: SparkJobFilterTableFields[]): SparkJobFilterModelActionTypes {
    return {
        type: UPDATE_SPARK_FILTERS,
        sparkFilters,
    }
}

// @ts-ignore
export class SparkFilterTable extends Model<typeof SparkFilterTable, SparkJobFilterTableFields> {
    static modelName = 'SparkFilterTable' as const;
    static fields = {
        filterName: attr(),
        filtervalue: attr(),
        isEdge: attr(),
    };

    static options = {
        idAttribute: 'filterName' as const
    };

    // @ts-ignore
    static updateSprakFilter(sparkFilterTable: ModelType<SparkFilterTable>, sparkFilter: SparkJobFilterTableFields) {
        const tableEntry = sparkFilterTable.withId(sparkFilter.filterName)
        if (tableEntry != null)
            tableEntry.update(sparkFilter);
        else
            sparkFilterTable.create(sparkFilter);
    }

    // @ts-ignore
    static reducer(action: SparkJobFilterModelActionTypes, sparkFilterTable: ModelType<SparkFilterTable>) {
        switch (action.type) {
            case UPDATE_SPARK_FILTER:
                SparkFilterTable.updateSprakFilter(sparkFilterTable, action.sparkFilter)
                break;
            case UPDATE_SPARK_FILTERS:
                action.sparkFilters.forEach(filter => {
                    SparkFilterTable.updateSprakFilter(sparkFilterTable, filter)
                })
                break;
            default:
                break;
        }
    }
}