import * as React from 'react';

// Fabric UI
import { Dropdown, IDropdownOption, DropdownMenuItemType } from 'office-ui-fabric-react/lib/Dropdown';
import { DefaultButton, Dialog, TextField, DialogFooter, PrimaryButton, DialogType } from 'office-ui-fabric-react';
import { DetectionResultIdArray } from '../../../Redux/ApiCalls/DetectionListToolbarActions';
import { withMsal, WithMsalProps } from '@azure/msal-react';

const options: IDropdownOption[] = [
  { key: 'activeHeader', text: 'Active', itemType: DropdownMenuItemType.Header },
  { key: 'In Progress', text: 'In Progress' },
  { key: 'Waiting for Response', text: 'Waiting for Response' },
  { key: 'Escalated to IR', text: 'Escalated to IR' },
  { key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider },
  { key: 'resolvedHeader', text: 'Resolved', itemType: DropdownMenuItemType.Header },
  { key: 'Insufficient Data', text: 'Insufficient Data' },
  { key: 'truePositiveHeader', text: 'True Positive (Keep Alerting)', itemType: DropdownMenuItemType.Header },
  { key: 'AttackBot', text: 'AttackBot' },
  { key: 'Pen Tester', text: 'Pen Tester' },
  { key: 'Poor Practice', text: 'Poor Practice' },
  { key: 'Debugging/Break-Glass', text: 'Debugging/Break-Glass' },
  { key: 'Incident', text: 'Incident' },
  { key: 'falsePositiveHeader', text: 'False Positive', itemType: DropdownMenuItemType.Header },
  { key: 'Bug', text: 'Bug' },
  { key: 'Normal Practice', text: 'Normal Practice' },
  { key: 'Abnormal Practice', text: 'Abnormal Practice' },
  { key: 'Tuning Required/Filtered', text: 'Tuning Required/Filtered' },
  { key: 'Emerging Activity to be learned in', text: 'Emerging Activity to be learned in' },
  { key: 'Security Monitoring Testing', text: 'Security Monitoring Testing' }
];

export interface IProps {
  thunkUpdateStatusDialogOpen: any;
  detectionResultId: DetectionResultIdArray;
  isVisibleProps: boolean;
}

type localState = {
  isVisible: boolean;
  selectStatus: string;
  comment: string;
}

class DetectionListLabeler extends React.Component<IProps & WithMsalProps, localState> {
  readonly state: localState;

  constructor(cProps: IProps & WithMsalProps) {
    super(cProps);
    this.state = {
      isVisible: false,
      selectStatus: "",
      comment: "",
    };
  }

  private executeStatusDialog(props: IProps, selectedStatus?: string, comment?: string, detectionResultIdArray?: DetectionResultIdArray): any {
    props.thunkUpdateStatusDialogOpen(this.props.msalContext, false, selectedStatus, comment, detectionResultIdArray);
    this.dismissStatusDialog(props);
  }

  private dismissStatusDialog(props: IProps): any {
    this.setState({ selectStatus: "" });
    this.setState({ comment: "" });
    this.setState({ isVisible: !this.state.isVisible });
  }

  private getDefaultMessage(): string {
    return "Resolution: <<How did you resolve this issue and what is your evidence, someone should be able to figure out what happened from this>> \n\nTrouble Triaging? Tell us why (aka.ms/vanquishtriagebug)";
  }
  public render(): JSX.Element {
    if(this.props.detectionResultId.length <= 0)
    {
      return (
        <Dialog
        hidden={this.props.isVisibleProps === this.state.isVisible}
        onDismiss={() => this.dismissStatusDialog(this.props)}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: 'You have not selected any detections'
        }}
        minWidth={550}
        maxWidth={1000}
      ></Dialog>
      )
    }
    return (
      <Dialog
        hidden={this.props.isVisibleProps === this.state.isVisible}
        onDismiss={() => this.dismissStatusDialog(this.props)}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: 'Status/Comment'
        }}
        minWidth={550}
        maxWidth={1000}
      >
        <Dropdown placeholder="Select an option" label="Set Status" options={options} selectedKey={this.state.selectStatus} onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IDropdownOption) => { this.setState({ selectStatus: option ? option.key as string : "" }) }} />
        <TextField label="Comment" multiline autoAdjustHeight resizable={true} placeholder={this.getDefaultMessage()} value={this.state.comment} onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => { this.setState({ comment: newValue ? newValue : '' }) }} />
        <DialogFooter>
          {this.state.selectStatus}
          <PrimaryButton onClick={() => { this.executeStatusDialog(this.props, this.state.selectStatus, this.state.comment, this.props.detectionResultId) }} text="Save" disabled={(this.state.selectStatus === "" || this.state.comment === "")} />
          <DefaultButton onClick={() => this.dismissStatusDialog(this.props)} text="Cancel" />
        </DialogFooter>
      </Dialog>
    );
  }
}


export default withMsal(DetectionListLabeler);
