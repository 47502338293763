import { appId, endpoint, kustoDb, newVanquishURL, tenant, tenantId } from '../vanquishConfig'
import { LogLevel } from "@azure/msal-browser";
// Endpoint URL

// App Registration ID


/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig = {
  auth: {
      clientId: appId,
      authority: endpoint + "/" + tenantId,
      redirectUri: "https://vanquishtriagedetection.office.net/"
  },
  cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {	
      loggerOptions: {	
          loggerCallback: (level: any, message: any, containsPii: any) => {	
              if (containsPii) {		
                  return;		
              }		
              switch (level) {
                  case LogLevel.Error:
                      console.error(message);
                      return;
                  case LogLevel.Info:
                      console.info(message);
                      return;
                  case LogLevel.Verbose:
                      console.debug(message);
                      return;
                  case LogLevel.Warning:
                      console.warn(message);
                      return;
                  default:
                      return;
              }	
          }	
      }	
  }
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */


export const loginRequest = {
  scopes: [kustoDb + "/.default"],
  prompt: "select_account"
};

export const renewTokenDalRequest = {
  scopes: [appId + "/.default"],//
};

export const renewTokenKustoRequest = {
    scopes: [kustoDb + "/.default"],//
  };


export const Agent = require('agentkeepalive')
export const keepAliveAgent = new Agent({
  maxSockets: 100,
  maxFreeSockets: 10,
  timeout: 60000, // active socket keepalive for 60 seconds
  freeSocketTimeout: 44326, // free socket keepalive for 30 seconds
})















