import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../index';
import { unSelectAllDetections } from '../StateModel/DetectionModel/DetectionModel';
import { dalbaseURL } from '../../vanquishConfig';
import { IMsalContext } from '@azure/msal-react';
import { addAadTokenToDetectionPostRequest } from '../../adal/ApiCall';



export interface DetectionResultIdArray {
  [index: number]: DetectionResultPostObject | undefined;
  push(item: DetectionResultPostObject | undefined): number;
  length: number;
  pop(): DetectionResultPostObject | undefined;
}

export interface DetectionResultPostObject {
  datetimeutc: number;
  detectiontype: string;
  entity: string;
  id: string;
  timebin: number;
  type: string;
  workload: string;
}


export function thunkUpdateStatusDialogOpen(contextType: IMsalContext, isStatusDialogOpen: boolean = true, selectStatus: string = "", comment: string = "", detectionResultIdArray: DetectionResultIdArray) {
  return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    if (isStatusDialogOpen === false && selectStatus !== "") {
      const test3 = dalbaseURL+ 'c2api/C2Detection/BulkUpdateDetectionAnalysis';
      const apiCall1 = addAadTokenToDetectionPostRequest(contextType, test3, selectStatus, comment, detectionResultIdArray);
      dispatch(unSelectAllDetections());
      return apiCall1;
    }
  }
}