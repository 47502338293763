import { ORM } from "redux-orm";
import { DetectionTable } from "./DetectionModel/DetectionModel"
import { DetectionPageTable } from "./DetectionPageModel/DetectionPageModel"
import { DetectionFilterTable } from "./DetectionFilterModel/DetectionFilterModel";
import { ICMTable } from "./ICMModel/ICMModel";
import { SparkJobStatsTable } from "./PipeLineTopologyModel/SparkJobStatsModel";
import { SparkFilterTable } from "./PipeLineTopologyModel/SparkFilterModel";
import { WorkloadTable } from "./WorkloadModel/WorkloadModel";
import { DetectionSummaryTable } from "./DetectionSummaryModel/DetectionSummaryModel";
import { EngagementInfoTable } from "./EngagementInfoModel/EngagementInfoModel";
import { TipTransactionTable } from "./TipTransactionModel/TipTransactionModel"

const schema = {
    WorkloadTable,
    ICMTable,
    DetectionTable,
    DetectionPageTable,
    DetectionFilterTable,
    SparkJobStatsTable,
    SparkFilterTable,
    DetectionSummaryTable,
    EngagementInfoTable,
    TipTransactionTable
};

export type Schema = typeof schema;

export const detectionOrm = new ORM<Schema>();

detectionOrm.register(WorkloadTable);
detectionOrm.register(ICMTable);
detectionOrm.register(DetectionTable);
detectionOrm.register(DetectionPageTable);
detectionOrm.register(DetectionFilterTable);
detectionOrm.register(SparkJobStatsTable);
detectionOrm.register(SparkFilterTable);
detectionOrm.register(DetectionSummaryTable);
detectionOrm.register(EngagementInfoTable);
detectionOrm.register(TipTransactionTable);