import { attr, Model, ModelType } from 'redux-orm'
import { TipTransactionTableFields } from '../TipTransactionModel/TipTransactionModel';


export interface SparkJobStatsTableFields {
    TransactionType: string;
    DataSourceOrSink: string;
    TableOrTopic: string;
    Application: string;
    SumCount: number;
    AvgCount: number;
}

export interface SparkJobStatsWithTip {
    TransactionType: string;
    DataSourceOrSink: string;
    TableOrTopic: string;
    Application: string;
    SumCount: number;
    AvgCount: number;
    tipData: TipTransactionTableFields[];
}

export interface SparkJobStatsTableEntry {
    UniqueIdentifier: string;
    TransactionType: string;
    DataSourceOrSink: string;
    TableOrTopic: string;
    Application: string;
    SumCount: number;
    AvgCount: number;
}

interface AddSparkJobStatsItemsAction {
    type: typeof ADD_SPARK_STATS;
    sparkJobStatsTableEntry: SparkJobStatsTableEntry[];
}

export type SparkJobStatsModelActionTypes = AddSparkJobStatsItemsAction


//Actions
export const ADD_SPARK_STATS = 'ADD_SPARK_STATS';

//Action Functions
export function addSparkStats(sparkStats: SparkJobStatsTableFields[]): SparkJobStatsModelActionTypes {
    const sparkJobStatsTableEntry = sparkStats.map(stat => {
        const uniqueIdentifier = stat.TransactionType + "_" + stat.DataSourceOrSink + "_" + stat.TableOrTopic + "_" + stat.Application;
        return {
            ...stat,
            UniqueIdentifier: uniqueIdentifier
        }
    })
    return {
        type: ADD_SPARK_STATS,
        sparkJobStatsTableEntry,
    }
}

// @ts-ignore
export class SparkJobStatsTable extends Model<typeof SparkJobStatsTable, SparkJobStatsTableEntry> {
    static modelName = 'SparkJobStatsTable' as const;
    static fields = {
        UniqueIdentifier: attr(),
        TransactionType: attr(),
        DataSourceOrSink: attr(),
        TableOrTopic: attr(),
        Application: attr(),
        SumCount: attr(),
        AvgCount: attr()
    };

    static options = {
        idAttribute: 'UniqueIdentifier' as const
    };

    // @ts-ignore
    static reducer(action: SparkJobStatsModelActionTypes, sparkStatTable: ModelType<SparkJobStatsTable>) {
        switch (action.type) {
            case 'ADD_SPARK_STATS':
                sparkStatTable.delete();
                action.sparkJobStatsTableEntry.forEach(stat => {
                    sparkStatTable.create(stat);
                })
                break;
            default:
                break;
        }
    }
}
