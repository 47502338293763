import React from 'react';

//Store
import { argumentFromUrl } from '../OCEDashBoard/OCEDashBoard';
import { DetectionListConstant, Pages } from '../../Redux/Constants/GeneralConstants';
import { getDetectionConstant } from '../../Redux/Constants/DetectionListConstants';
import { IMsalContext, withMsal, WithMsalProps } from '@azure/msal-react';
import LoadDetectionFromKustoContainer from '../../containers/LoadDetectionFromKustoContainer';


export interface urlLinks {
  EntityDetails: string;
  urlParameters: argumentFromUrl;
}

export interface IProps {
  urlParameters: argumentFromUrl;
  loadDetectionsPostApiCall: (msalContext: IMsalContext, detectionListConstant: DetectionListConstant, options: any) => void;
}

type localState = {
}

const _getKeyValue_ = (key: string) => (obj: Record<string, any>) => obj[key];


class LoadQueryFromCode extends React.Component<IProps & WithMsalProps, localState>{
  readonly state: localState = {
  }

  readonly LoadQueryFromCodeConstant: DetectionListConstant = _getKeyValue_(Pages.LoadQueryFromCode)(getDetectionConstant());

  constructor(cProps: IProps & WithMsalProps) {
    super(cProps);

  }

  public render(): JSX.Element {
    const query = this.props.urlParameters["func"] as string
    
    return (
      <div>
      <LoadDetectionFromKustoContainer
        urlParameters={this.props.urlParameters}
        detectionListConstant={this.LoadQueryFromCodeConstant}
        msalContext={this.props.msalContext}
        query={query}
      /></div>
      );
    
  }
}

export default withMsal(LoadQueryFromCode);
