// Fabric UI
import 'office-ui-fabric-react/dist/css/fabric.css';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import React from 'react';
import { Ref } from 'redux-orm';
import { DetectionFilterTableField } from '../../../Redux/StateModel/DetectionFilterModel/DetectionFilterInterface';
import { DetectionFilterTable } from '../../../Redux/StateModel/DetectionFilterModel/DetectionFilterModel';


export interface IProps {
  // @ts-ignore
  filter: Ref<DetectionFilterTable>
  count: number,
  // @ts-ignore
  updateFilterClick: (event: any, filter: Ref<DetectionFilterTable>) => void
}

const defaultStyles = {
  checkmark: {
    background: "none"
  }
};

const greenStyles = {
  checkmark: {
    background: "#7de395",
    color: "#0ecf72",
    fontWeight: "bold"
  }
};

const redStyles = {
  checkmark: {
    background: "#ff7d7d",
    color: "#d92e2e",
    fontWeight: "bold"
  }
};


const DetectionListFilterToggle: React.FC<IProps> = ({ filter, updateFilterClick, count }: IProps) => {

  const isChecked = filter.applied;
  const isCtrlSelected = filter.ctrlApplied;
  const styles = isCtrlSelected ? redStyles : greenStyles;
  return (
    <Checkbox
      key={filter.filterName}
      label={filter.filterName + " (" + String(count) + ")"}
      className={isCtrlSelected ? "redCheckbox" : "greenCheckbox"}
      checked={isChecked}
      onChange={(event) => updateFilterClick(event, { ...filter, applied: !filter.applied } as DetectionFilterTableField)}
      styles={isChecked ? styles : defaultStyles} />
  );
}

export default DetectionListFilterToggle;
