import { attr, Model, ModelType } from 'redux-orm'
import * as actions from '../../Constants/Actions'

export interface ICMTableFields {
    CreateDate: Date;
    ImpactStartDate: Date;
    IncidentId: number;
    MitigateDate: Date;
    MitigatedBy: string;
    Mitigation: string;
    ModifiedDate: Date;
    OwningContactAlias: string;
    OwningTeamName: string;
    ResolveDate: Date;
    ResolvedBy: string;
    RoutingId: string;
    Severity: number;
    SourceIncidentId: string;
    Status: string;
    Title: string;
}

export interface AddICMListItemsAction {
    type: typeof actions.ADD_ICMDATA;
    icmData: ICMTableFields[];
}

export type ICMDataModelActionTypes = AddICMListItemsAction

//Action Functions
export function addICMdata(icmData: ICMTableFields[]): ICMDataModelActionTypes {
    return {
        type: actions.ADD_ICMDATA,
        icmData
    }
}

// @ts-ignore
export class ICMTable extends Model<typeof ICMTable, ICMTableFields> {
    static modelName = 'ICMTable' as const;
    static fields = {
        CreateDate: attr(),
        ImpactStartDate: attr(),
        IncidentId: attr(),
        MitigateDate: attr(),
        MitigatedBy: attr(),
        Mitigation: attr(),
        ModifiedDate: attr(),
        OwningContactAlias: attr(),
        OwningTeamName: attr(),
        ResolveDate: attr(),
        ResolvedBy: attr(),
        RoutingId: attr(),
        Severity: attr(),
        SourceIncidentId: attr(),
        Status: attr(),
        Title: attr()
    };

    static options = {
        idAttribute: 'SourceIncidentId' as const
    };

    // @ts-ignore
    static reducer(action: ICMDataModelActionTypes, icmTable: ModelType<ICMTable>) {
        switch (action.type) {
            case actions.ADD_ICMDATA:
                action.icmData.forEach(val => {
                    icmTable.create(val);
                })
                break;
            default:
                break;
        }
    }
}
