import { Ref, TableState } from "redux-orm";
import { DetectionPageTable } from "./DetectionPageModel";

export interface IProps {
    // @ts-ignore
    detectionPageTable: TableState<typeof DetectionPageTable>;
    detectionOwner: string;
}

// @ts-ignore
export const getDetectionsPageByOwner = (props: IProps): Ref<DetectionPageTable> => {
    return Object.values(props.detectionPageTable.itemsById).filter(val => props.detectionOwner === val.pageName)[0]
};
