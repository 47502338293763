import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

// Store
import { AppState } from '../Redux';

// Components
import PipelineTopology, { dataFlowObject } from '../components/PipelineTopology/PipelineTopology';
import { updateSparkFilter } from '../Redux/StateModel/PipeLineTopologyModel/SparkFilterModel';
import { getEdgeFilters, getNodeFilters, getSparkParsedData } from '../Redux/StateModel/PipeLineTopologyModel/PipelineTopologySelector';
import { TipTransactionTableFields } from '../Redux/StateModel/TipTransactionModel/TipTransactionModel';
import { getTipList } from '../Redux/StateModel/TipTransactionModel/TipTransactionSelector';
import { getSparkData } from '../Redux/ApiCalls/DetectionActions';
import { IMsalContext } from '@azure/msal-react';


interface IProps {

}


const mapStateToProps = (state: AppState, ownProps: IProps) => {
  return {
    input: getSparkParsedData({
      pipeLineData: Object.values(state.orm.SparkJobStatsTable.itemsById),
      filters: Object.values(state.orm.SparkFilterTable.itemsById),
      tipJobData: Object.values(state.orm.TipTransactionTable.itemsById) as TipTransactionTableFields[]
    }) as unknown as dataFlowObject,
    nodeToEnter: getNodeFilters({
      pipeLineData: Object.values(state.orm.SparkJobStatsTable.itemsById),
      filters: Object.values(state.orm.SparkFilterTable.itemsById)
    }),
    edgeToEnter: getEdgeFilters({
      pipeLineData: Object.values(state.orm.SparkJobStatsTable.itemsById),
      filters: Object.values(state.orm.SparkFilterTable.itemsById)
    }),
    tipList: getTipList({
      tipJobData: Object.values(state.orm.TipTransactionTable.itemsById) as TipTransactionTableFields[]
    })
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
    userChangedFilter: (filterName: string, isEdge: boolean, value: boolean) =>
      dispatch(updateSparkFilter({ filterName: filterName, isEdge: isEdge, filtervalue: value })),
    getSparkData: (msalContext: IMsalContext) => dispatch(getSparkData(msalContext)),
  }
}

const PipelineTopologyContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PipelineTopology);

export default PipelineTopologyContainer;
