import moment, { Moment } from 'moment';
import { dataObject, errorMessage, KustoAPIObject, KustoColum, pullDataMethod } from '../StateModel/DetectionPageModel/DetectionPageModel'

// Redux
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../index';

// Types
import { addDetectiondata, DetectionTableInput } from '../StateModel/DetectionModel/DetectionModel';
import { addDetectionPageData, DetectionPageTableUpdate } from '../StateModel/DetectionPageModel/DetectionPageModel';
import { addFilters, addIcmDataFilters } from '../StateModel/DetectionFilterModel/DetectionFilterActions';
import { addICMdata, ICMTableFields } from '../StateModel/ICMModel/ICMModel';
import { addSparkStats, SparkJobStatsTableFields } from '../StateModel/PipeLineTopologyModel/SparkJobStatsModel';
import { SparkJobFilterTableFields, updateSparkFilters } from '../StateModel/PipeLineTopologyModel/SparkFilterModel';
import { TipTransaction, updateTipTransactions } from '../StateModel/TipTransactionModel/TipTransactionModel';
import { dalURL, kustoDbQuery } from '../../vanquishConfig';
import { getDetectionConstant } from '../Constants/DetectionListConstants';
import { DetectionFilterLayout, DetectionListConstant } from '../Constants/GeneralConstants';
import { IMsalContext } from '@azure/msal-react';
import { addAadTokenToGetRequest, addAadTokenToPostRequest } from '../../adal/ApiCall';
import internal from 'stream';

export interface WorkloadInfo {
  Current_Status: string;
  Sovereign: string | null;
  Workload: string;
}

export interface Workload {
  Is_Authorized: boolean;
  WorkloadInfo: WorkloadInfo;
}


export function importICMData(msalContext: IMsalContext, startTime: Moment, stopTime: Moment, owningTeam: string, extraParameters: string, detectionListConstant: DetectionListConstant, pullDataMethod: pullDataMethod) {
  return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    const newPage: DetectionPageTableUpdate = {
      pageName: detectionListConstant.pageName,
      loadingDetectionListItem: true,
      startTime: startTime,
      stopTime: stopTime,
      pageURL: detectionListConstant.pageUrl,
      extraURLInfo: extraParameters,
      pullDataMethod: pullDataMethod
    }
    const link = appendTime(dalURL + "GetActiveAlerts/", startTime, stopTime, owningTeam);

    //Create a new page to display the data to, and set it as loading the data
    dispatch(addDetectionPageData(newPage));

    let [apiCallData, apiCallWorkloadsLinkData] = await Promise.all(
      [addAadTokenToGetRequest(msalContext, appendTime(detectionListConstant.pageUrl, startTime, stopTime, extraParameters)),
        addAadTokenToGetRequest(msalContext, link)])

    //Update the page to show the data has been loaded
    dispatch(addDetectionPageData({ ...newPage, loadingDetectionListItem: false }));
    //Update the Filters
    dispatch(addFilters(apiCallData as DetectionTableInput[], detectionListConstant));
    //Update the data
    dispatch(addDetectiondata(apiCallData as DetectionTableInput[], detectionListConstant.pageName));
    //Update ICM Data
    dispatch(addIcmDataFilters(apiCallWorkloadsLinkData as ICMTableFields[], getDetectionConstant()));
    dispatch(addICMdata(apiCallWorkloadsLinkData as ICMTableFields[]));

    
    
    
    
  }
}

export function appendTime(url: string, startTime: Moment, stopTime: Moment, extraParameters: string) {
  const millisecondsStart = startTime.valueOf() * 10000 + 621355968000000000;
  const millisecondsStop = stopTime.valueOf() * 10000 + 621355968000000000;
  return url + millisecondsStart.toString() + '/' + millisecondsStop.toString() + '/' + extraParameters;

}

export function ProcessPostFromKustoIntoNeededFormat(kustoPostData: KustoAPIObject) : DetectionTableInput[]{
  const columns : KustoColum[] = (kustoPostData.Columns == undefined) ? [] : kustoPostData.Columns
  const rows : any[][] = (kustoPostData.Rows == undefined) ? [] : kustoPostData.Rows

  //zip data from kusto into the same format that everything else uses
  return rows.map((row: any[]) => {
      var obj:dataObject = {}
      row.forEach((value, i:number) => {
          // as any
          if(typeof value === 'object')
            obj[columns[i].ColumnName] = JSON.stringify(value, null, 2)
          else
            obj[columns[i].ColumnName] = value
      })
      return obj
  }) as DetectionTableInput[]
  
}

function getColumnNames(kustoPostData: KustoAPIObject) : string[] {
  const columns : KustoColum[] = (kustoPostData.Columns == undefined) ? [] : kustoPostData.Columns
  return columns.map(val => val.ColumnName)
}

export function loadDetectionsPostApiCall(msalContext: IMsalContext, detectionListConstant: DetectionListConstant, options: any = {}) {
  return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    const newPage: DetectionPageTableUpdate = {
      pageName: detectionListConstant.pageName,
      loadingDetectionListItem: true,
      detectionColumns:[],
      startTime: moment(),
      stopTime: moment(),
      pageURL: detectionListConstant.pageUrl,
      extraURLInfo: "not applicable",
      pullDataMethod: pullDataMethod.query
    }

    //Create a new page to display the data to, and set it as loading the data
    dispatch(addDetectionPageData(newPage));
    
    var apiCallData 
    try{
      apiCallData = (await addAadTokenToPostRequest(msalContext, kustoDbQuery, options));
    }catch(e){
      dispatch(addDetectionPageData({ ...newPage, loadingDetectionListItem: false, pageErrorString:e as errorMessage}));
      return;
    }
    const data = ProcessPostFromKustoIntoNeededFormat(apiCallData[2])

    var buildFilterList : DetectionFilterLayout = {}
    const columns : KustoColum[] = (apiCallData[2].Columns == undefined) ? [] : apiCallData[2].Columns
    columns.forEach(val => {
      buildFilterList[val.ColumnName] = {field:val.ColumnName, presets: {}}
    })

    //Update the page to show the data has been loaded
    //Add custom columns if a project statement is added
    const columnsData : string[] = getColumnNames(apiCallData[2]);
    switch(JSON.stringify(options).indexOf("project")){
      case -1:
        dispatch(addDetectionPageData({ ...newPage, loadingDetectionListItem: false }));
        break;
      default:
        dispatch(addDetectionPageData({ ...newPage, loadingDetectionListItem: false, detectionColumns:columnsData }));
        break;
    }
    //Update the Filters
    dispatch(addFilters(data, {...detectionListConstant, detectionFilterLayout:buildFilterList}));
    //Update the data
    dispatch(addDetectiondata(data, detectionListConstant.pageName));
  }
}


export function thunkLoadDetectionsApiCall(msalContext: IMsalContext, startTime: Moment, stopTime: Moment, extraParameters: string, detectionListConstant: DetectionListConstant, pullDataMethod: pullDataMethod) {
  return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    const newPage: DetectionPageTableUpdate = {
      pageName: detectionListConstant.pageName,
      loadingDetectionListItem: true,
      startTime: startTime,
      stopTime: stopTime,
      pageURL: detectionListConstant.pageUrl,
      extraURLInfo: extraParameters,
      pullDataMethod: pullDataMethod
    }

    //Create a new page to display the data to, and set it as loading the data
    dispatch(addDetectionPageData(newPage));

    var apiCallData 
    try{
      apiCallData = (await addAadTokenToGetRequest(msalContext, appendTime(detectionListConstant.pageUrl, startTime, stopTime, extraParameters))) as DetectionTableInput[];
    }catch(e){
      dispatch(addDetectionPageData({ ...newPage, loadingDetectionListItem: false, pageErrorString:e as errorMessage}));
      return;
    }

    //Update the page to show the data has been loaded
    dispatch(addDetectionPageData({ ...newPage, loadingDetectionListItem: false }));
    //Update the Filters
    dispatch(addFilters(apiCallData, detectionListConstant));
    //Update the data
    dispatch(addDetectiondata(apiCallData, detectionListConstant.pageName));
  }
}



export function getSparkData(msalContext: IMsalContext) {
  return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    const filters: SparkJobFilterTableFields[] = [
      { filterName: "EventHubs", filtervalue: false, isEdge: true },
      { filterName: "RawEventHubs", filtervalue: true, isEdge: true },
      { filterName: "AzureSql", filtervalue: true, isEdge: true },
      { filterName: "CosmosDb", filtervalue: true, isEdge: true },
      { filterName: "AadEnrichmentProvider", filtervalue: true, isEdge: true },
      { filterName: "Parquet", filtervalue: true, isEdge: true },
      { filterName: "Kusto", filtervalue: true, isEdge: true },
    ]
    dispatch(updateSparkFilters(filters))
    const workloadsLink = dalURL + "GetSparkJobStats/";
    const tipTransactionData = dalURL + "GetLatestTip/";
    const apiCallWorkloadsLinkData = (await addAadTokenToGetRequest(msalContext, workloadsLink)) as SparkJobStatsTableFields[];
    const apiCallTipTransactionData = (await addAadTokenToGetRequest(msalContext, tipTransactionData)) as TipTransaction[];


    dispatch(addSparkStats(apiCallWorkloadsLinkData));
    dispatch(updateTipTransactions(apiCallTipTransactionData));

  }
}


