import { Ref } from "redux-orm";
import { createSelector } from "reselect";
import { DetectionTableFields } from "../DetectionModel/DetectionModel";
import { applyFilterToTheDetections, getAllFilters } from "../DetectionModel/DetectionSelector";
import { DetectionFilterTable } from "./DetectionFilterModel";

export interface IProps {
    detectionOwner: string;
    filterSubGroup: string
}

export interface filter {
    // @ts-ignore
    filter: Ref<DetectionFilterTable>;
}

export const getFilterOwner = (props: IProps): string => {
    return props.detectionOwner
}

export const getFilterSubGroup = (props: IProps): string => {
    return props.filterSubGroup
}

// @ts-ignore
export const getFilter = (props: filter): Ref<DetectionFilterTable> => {
    return props.filter;
}

export const getFiltersByDetectionOwnerAndSubGroup = createSelector(
    [getAllFilters, getFilterOwner, getFilterSubGroup],
    // @ts-ignore
    (filters, owner, subGroup): Ref<DetectionFilterTable>[] => {
        return filters.filter(filter => {
            return filter.detectionFilterOwner === owner &&
                filter.filterSubGroup === subGroup
        })
    }
)

export const detectionHasSubFieldValuePair = (subField: string, value: string | number, detection: DetectionTableFields) => {
    if (detection.hasOwnProperty(subField))
        return detection[subField] == value;
    else if (detection.hasOwnProperty("additional_data") && detection.additional_data.hasOwnProperty(subField))
        return detection.additional_data[subField] == value;
    else
        return false;
}

export const getDisplayedFilters = createSelector(
    [getFiltersByDetectionOwnerAndSubGroup, applyFilterToTheDetections],
    (filters, detections) => {
        return filters.filter(filter => {
            let isValid = filter.applied;
            detections.forEach(detection => {
                isValid = isValid || detectionHasSubFieldValuePair(filter.filterSubGroup, filter.filterName, detection);
            })
            return isValid;
        })
    }
)

export const getFilterCount = createSelector(
    [getFilter, applyFilterToTheDetections],
    (filter, detections): number => {
        let count = 0;
        detections.forEach(detection => {
            if(detectionHasSubFieldValuePair(filter.filterSubGroup, filter.filterName, detection)){
                count += 1;
            };
        });
        return count;
    }
)
