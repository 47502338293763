import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

// Store
import { AppState } from '../Redux';

// Components
import { argumentFromUrl } from '../components/OCEDashBoard/OCEDashBoard';
import { applyFilterToTheDetections } from '../Redux/StateModel/DetectionModel/DetectionSelector';
import { getDetectionsPageByOwner } from '../Redux/StateModel/DetectionPageModel/DetectionPageSelector';
import { DetectionListConstant } from '../Redux/Constants/GeneralConstants';
import DetectionPage from '../components/DetectionList/DetectionPage';

export interface IProps {
  urlParameters: argumentFromUrl;
  detectionListConstant: DetectionListConstant;
}

const mapStateToProps = (state: AppState, ownProps: IProps) => {
  return {
    visibleItems: applyFilterToTheDetections({
      detectionTable: state.orm.DetectionTable,
      detectionSummaryTable: state.orm.DetectionSummaryTable,
      detectionOwner: ownProps.detectionListConstant.pageName,
      icmTable: state.orm.ICMTable,
      detectionListFilterTable: state.orm.DetectionFilterTable,
    }),
    detectionPageInfo: getDetectionsPageByOwner({
      detectionPageTable: state.orm.DetectionPageTable,
      detectionOwner: ownProps.detectionListConstant.pageName
    })
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
  }
}

const DetectionPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DetectionPage)

export default DetectionPageContainer
