import React from 'react';
import ReactDOM from 'react-dom';
import store from './Redux';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { loginRequest, msalConfig } from './adal/adalConfig';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { StartApp, TriageAlerts } from './components/App/App';

const msalInstance = new PublicClientApplication(msalConfig);

export const LogUserIn = () => {
  const { instance } = useMsal();

  const handleLogin = (loginType: string) => {
      if (loginType === "popup") {
          instance.loginPopup(loginRequest).catch(e => {
              console.log(e);
          });
      } else if (loginType === "redirect") {
          instance.loginRedirect(loginRequest).catch(e => {
              console.log(e);
          });
      }
  }
  return (
      <div>You should be redirected to a sign in page shortly {handleLogin("redirect")}</div>
  )
}



ReactDOM.render(
  //Need to refactor code to be able to turn strict mode on
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <UnauthenticatedTemplate>
            <LogUserIn />
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          <Router>
            <Switch>
              <Route path="/query" component={TriageAlerts} />
              <Route path="/:OCEDashboard?" component={StartApp} />
            </Switch>
          </Router>
        </AuthenticatedTemplate>
      </Provider>
    </MsalProvider>
  </React.StrictMode>
  , document.getElementById('root')
);





// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
