import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

// Store
import { AppState } from '../Redux';

// Components
import { applyFilterToTheDetections, getSelectedDetections } from '../Redux/StateModel/DetectionModel/DetectionSelector';
import { getDetectionsPageByOwner } from '../Redux/StateModel/DetectionPageModel/DetectionPageSelector';
import { DetectionSelectionInfo, selectDetectionData } from '../Redux/StateModel/DetectionModel/DetectionModel';
import { DetectionPageTableFields, updatePage } from '../Redux/StateModel/DetectionPageModel/DetectionPageModel';
import { DetectionListConstant } from '../Redux/Constants/GeneralConstants';
import DetectionList from '../components/DetectionList/DetectionList';

export interface IProps {
  detectionListConstant: DetectionListConstant;
}

const mapStateToProps = (state: AppState, ownProps: IProps) => {
  return {
    detectionCount: applyFilterToTheDetections({
      detectionTable: state.orm.DetectionTable,
      detectionSummaryTable: state.orm.DetectionSummaryTable,
      detectionOwner: ownProps.detectionListConstant.pageName,
      icmTable: state.orm.ICMTable,
      detectionListFilterTable: state.orm.DetectionFilterTable,
    }).length,
    visibleItems: applyFilterToTheDetections({
      detectionTable: state.orm.DetectionTable,
      detectionSummaryTable: state.orm.DetectionSummaryTable,
      detectionOwner: ownProps.detectionListConstant.pageName,
      icmTable: state.orm.ICMTable,
      detectionListFilterTable: state.orm.DetectionFilterTable,
    }),
    selectedDetections: getSelectedDetections({
      detectionTable: state.orm.DetectionTable,
      detectionSummaryTable: state.orm.DetectionSummaryTable,
      detectionOwner: ownProps.detectionListConstant.pageName,
      icmTable: state.orm.ICMTable,
      detectionListFilterTable: state.orm.DetectionFilterTable,
    }),
    detectionPageInfo: getDetectionsPageByOwner({
      detectionPageTable: state.orm.DetectionPageTable,
      detectionOwner: ownProps.detectionListConstant.pageName
    })

  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
    selectDetectionData: (selectionChange: DetectionSelectionInfo[]) => dispatch(selectDetectionData(selectionChange)),
    updatePage: (newPage: DetectionPageTableFields) => dispatch(updatePage(newPage))
  }
}

const DetectionListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DetectionList)

export default DetectionListContainer
