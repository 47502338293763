import { connect } from 'react-redux';
import { Action } from 'redux';
import { Moment } from 'moment';
import { ThunkDispatch } from 'redux-thunk';

// Store
import { AppState } from '../Redux';
import { thunkLoadDetectionsApiCall } from '../Redux/ApiCalls/DetectionActions';
import { pullDataMethod } from '../Redux/StateModel/DetectionPageModel/DetectionPageModel'

// Components
import { argumentFromUrl, urlLinks } from '../components/OCEDashBoard/OCEDashBoard';
import WorkloadFilter from '../components/WorkloadFilter/WorkLoadFilter';
import { DetectionListConstant } from '../Redux/Constants/GeneralConstants';
import { IMsalContext } from '@azure/msal-react';

export interface IProps {
  urlLink: urlLinks;
  urlParameters: argumentFromUrl;
  detectionListConstant: DetectionListConstant;
}


const mapStateToProps = (state: AppState, ownProps: IProps) => {
  return {
    workloads: Object.values(state.orm.WorkloadTable.itemsById).filter(val=>val.currentStatus!="Decomissioned")
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
    thunkLoadDetectionsApiCall: (msalContext: IMsalContext, startTime: Moment, stopTime: Moment, extraParameters: string, detectionListConstant: DetectionListConstant, pullDataMethod: pullDataMethod) =>
      dispatch(thunkLoadDetectionsApiCall(msalContext, startTime, stopTime, extraParameters, detectionListConstant, pullDataMethod)),
  }
}

const WorkloadFilterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkloadFilter);

export default WorkloadFilterContainer;
