import { dalURL, icmEnabled, kustoDb } from "../../vanquishConfig";
import { defaultRenderFunction, defaultRowRenderFunction, DetectionListConstants, labelDetectionHistoryRenderFunction, Pages, workloadRowRenderFunction } from "./GeneralConstants";


export const getDetectionConstant = (): DetectionListConstants => {
  if(icmEnabled)
  {
    return detectionListConstantsWithICM;
  }else {
    return detectionListConstantsWithOutICM;
  }
   
}

const detectionListConstantsWithICM: DetectionListConstants = {
  [Pages.UnTriageAlerts]: {
    pageName: Pages.UnTriageAlerts,
    pageUrl: dalURL + 'GetUnTriagePaging/',
    detectionFilterLayout: {
      "On Call": {field: "OwningContactAlias", presets: {}},
      "Owning Team": {removeByDefault: true, field: "OwningTeamName", presets: {
        "EXCHANGE\\ExchangeSecurityAlerts": true,
        "EXCHANGE\\ExchangeSecurityAlerts-US": true,
        "OFFICE365FOUNDATIONSSECURITYSIGNALS\\M365DetectSecurityAlerts": true,
        "OFFICE365FOUNDATIONSSECURITYSIGNALS\\M365DetectITARSecurityAlerts": true
      }},
      "Detection Name": {field: "name", presets: {}},
      "Action": {field: "action", presets: {"Urgent": true}},
      "Severity": {removeByDefault: true, field: "Severity", presets: {"2": true}}
    },
    detectionColumns: [
      { key: 'dateTime', name: 'Timestamp (UTC)', fieldName: 'date_time_utc', minWidth: 50, maxWidth: 150, isResizable: true },
      { key: 'workload', name: 'Workload', fieldName: 'workload', minWidth: 50, maxWidth: 125, isResizable: true },
      { key: 'targetEntity', name: 'Target Entity', fieldName: 'target_entity', minWidth: 50, maxWidth: 200, isResizable: true },
      { key: 'detectionType', name: 'Detection Name', fieldName: 'name', minWidth: 50, maxWidth: 150, isResizable: true },
      { key: 'action', name: 'Action', fieldName: 'action', minWidth: 50, maxWidth: 100, isResizable: true },
      { key: 'icmStatus', name: 'ICM Status', fieldName: 'Status', minWidth: 100, maxWidth: 150, isResizable: true },
      { key: 'owningContactAlias', name: 'On Call', fieldName: 'OwningContactAlias', minWidth: 100, maxWidth: 100, isResizable: true },
      { key: 'icm', name: 'ICM', fieldName: 'IncidentId', minWidth: 100, maxWidth: 100, isResizable: true },
      { key: 'owningTeam', name: 'Owning Team', fieldName: 'OwningTeamName', minWidth: 100, maxWidth: 250, isResizable: true },
      { key: 'delay', name: 'Latency (In Min)', fieldName: 'delay', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'helpButton', name: 'ReportAlert', fieldName: 'helpButton', minWidth: 50, maxWidth: 100, isResizable: true }
    ],
    renderFunction: defaultRenderFunction,
    rowRenderFunction: defaultRowRenderFunction
  },
  [Pages.ActiveDetections]: {
    pageName: Pages.ActiveDetections,
    pageUrl: dalURL + 'GetActiveDetections/',
    detectionFilterLayout: {
      "On Call": {field: "OwningContactAlias", presets: {}},
      "Owning Team": {field: "OwningTeamName", presets: {}},
      "Detection Name": {field: "name", presets: {}}
    },
    detectionColumns: [
      { key: 'dateTime', name: 'Timestamp (UTC)', fieldName: 'date_time_utc', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'workload', name: 'Workload', fieldName: 'workload', minWidth: 100, maxWidth: 100, isResizable: true },
      { key: 'targetEntity', name: 'Target Entity', fieldName: 'target_entity', minWidth: 100, maxWidth: 300, isResizable: true },
      { key: 'owningContactAlias', name: 'On Call', fieldName: 'OwningContactAlias', minWidth: 100, maxWidth: 100, isResizable: true },
      { key: 'icm', name: 'ICM', fieldName: 'IncidentId', minWidth: 100, maxWidth: 100, isResizable: true },
      { key: 'owningTeam', name: 'Owning Team', fieldName: 'OwningTeamName', minWidth: 100, maxWidth: 250, isResizable: true },
      { key: 'detectionType', name: 'Detection Name', fieldName: 'name', minWidth: 50, maxWidth: 150, isResizable: true },
      { key: 'substatus', name: 'Substatus', fieldName: 'analysis_substatus', minWidth: 100, maxWidth: 150, isResizable: true },
      { key: 'status', name: 'Status', fieldName: 'analysis_status', minWidth: 50, maxWidth: 50, isResizable: true },
      { key: 'Alias', name: 'Alias', fieldName: 'Alias', minWidth: 100, maxWidth: 250, isResizable: true },
      { key: 'label', name: 'Message', fieldName: 'label', minWidth: 400, maxWidth: 600, isResizable: true },
      { key: 'helpButton', name: 'ReportAlert', fieldName: 'helpButton', minWidth: 75, maxWidth: 100, isResizable: true }
    ],
    renderFunction: defaultRenderFunction,
    rowRenderFunction: defaultRowRenderFunction
  },
  [Pages.LabelDetectionHistory]: {
    pageName: Pages.LabelDetectionHistory,
    pageUrl: dalURL + "GetDetectionLabelHistory/",
    detectionFilterLayout: {
      "Status": {field: "analysis_status", presets: {}},
      "Substatus": {field: "analysis_substatus", presets: {}},
      "Detection Name": {field: "name", presets: {}}
    },
    detectionColumns: [
      { key: 'count', name: 'Count', fieldName: 'score', minWidth: 25, maxWidth: 50, isResizable: true },
      { key: 'dateTime', name: 'Date', fieldName: 'detection_result_time_bin', minWidth: 100, maxWidth: 125, isResizable: true },
      { key: 'workload', name: 'Workload', fieldName: 'workload', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'targetEntity', name: 'Target Entity', fieldName: 'target_entity', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'detectionType', name: 'Detection Name', fieldName: 'name', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'alias', name: 'Alias', fieldName: 'name', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'label', name: 'Message', fieldName: 'label', minWidth: 150, maxWidth: 200, isResizable: true }
    ],
    renderFunction: labelDetectionHistoryRenderFunction,
    rowRenderFunction: workloadRowRenderFunction
  },
  [Pages.LabelThrottledDetectionHistory]: {
    pageName: Pages.LabelThrottledDetectionHistory,
    pageUrl: dalURL + "GetThrottledUrgentAlerts/",
    detectionFilterLayout: {
      "Threshold": {field: "threshold", presets: {}},
      "Workload": {field: "workload", presets: {}},
      "Detection Name": {field: "detectionType", presets: {}},
    },
    detectionColumns: [
      { key: 'workload', name: 'Workload', fieldName: 'workload', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'ingestionTime', name: 'IngestionTime', fieldName: 'ingestionTime', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'detectionType', name: 'Detection Name', fieldName: 'detectionType', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'threshold', name: 'Threshold', fieldName: 'threshold', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'message', name: 'Message', fieldName: 'message', minWidth: 150, maxWidth: 200, isResizable: true }
    ],
    renderFunction: defaultRenderFunction,
    rowRenderFunction: workloadRowRenderFunction
  },
  [Pages.LoadKustoDetections]: {
    pageName: Pages.LoadKustoDetections,
    pageUrl: kustoDb,
    detectionFilterLayout: {},
    detectionColumns: [
      { key: 'workload', name: 'workload', fieldName: 'workload', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'target_entity_type', name: 'target_entity_type', fieldName: 'target_entity_type', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'target_entity', name: 'target_entity', fieldName: 'target_entity', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'date_time_utc', name: 'date_time_utc', fieldName: 'date_time_utc', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'id', name: 'id', fieldName: 'id', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'analysis_status', name: 'analysis_status', fieldName: 'analysis_status', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'analysis_substatus', name: 'analysis_substatus', fieldName: 'analysis_substatus', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'detection_type', name: 'detection_type', fieldName: 'detection_type', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'name', name: 'name', fieldName: 'name', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'label', name: 'label', fieldName: 'label', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'filtered', name: 'filtered', fieldName: 'filtered', minWidth: 100, maxWidth: 200, isResizable: true },
      //{ key: 'additional_data', name: 'additional_data', fieldName: 'additional_data', minWidth: 100, maxWidth: 200, isResizable: true }
    ],
    renderFunction: defaultRenderFunction,
    rowRenderFunction: defaultRowRenderFunction
  },
  [Pages.LoadQueryFromCode]: {
    pageName: Pages.LoadQueryFromCode,
    pageUrl: kustoDb,
    detectionFilterLayout: {},
    detectionColumns: [
      { key: 'workload', name: 'workload', fieldName: 'workload', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'target_entity_type', name: 'target_entity_type', fieldName: 'target_entity_type', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'target_entity', name: 'target_entity', fieldName: 'target_entity', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'date_time_utc', name: 'date_time_utc', fieldName: 'date_time_utc', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'id', name: 'id', fieldName: 'id', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'analysis_status', name: 'analysis_status', fieldName: 'analysis_status', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'analysis_substatus', name: 'analysis_substatus', fieldName: 'analysis_substatus', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'detection_type', name: 'detection_type', fieldName: 'detection_type', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'name', name: 'name', fieldName: 'name', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'label', name: 'label', fieldName: 'label', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'filtered', name: 'filtered', fieldName: 'filtered', minWidth: 100, maxWidth: 200, isResizable: true },
      //{ key: 'additional_data', name: 'additional_data', fieldName: 'additional_data', minWidth: 100, maxWidth: 200, isResizable: true }
    ],
    renderFunction: defaultRenderFunction,
    rowRenderFunction: defaultRowRenderFunction
  }
}

const detectionListConstantsWithOutICM: DetectionListConstants = {
  [Pages.UnTriageAlerts]: {
    pageName: Pages.UnTriageAlerts,
    pageUrl: dalURL + 'GetUnTriagePaging/',
    detectionFilterLayout: {
      "Detection Name": {field: "name", presets: {}},
      "Action": {field: "action", presets: {"Urgent": true}}
    },
    detectionColumns: [
      { key: 'dateTime', name: 'Timestamp (UTC)', fieldName: 'date_time_utc', minWidth: 50, maxWidth: 150, isResizable: true },
      { key: 'workload', name: 'Workload', fieldName: 'workload', minWidth: 50, maxWidth: 125, isResizable: true },
      { key: 'targetEntity', name: 'Target Entity', fieldName: 'target_entity', minWidth: 50, maxWidth: 200, isResizable: true },
      { key: 'detectionType', name: 'Detection Name', fieldName: 'name', minWidth: 50, maxWidth: 150, isResizable: true },
      { key: 'action', name: 'Action', fieldName: 'action', minWidth: 50, maxWidth: 100, isResizable: true },
      { key: 'delay', name: 'Latency (In Min)', fieldName: 'delay', minWidth: 100, maxWidth: 200, isResizable: true }
    ],
    renderFunction: defaultRenderFunction,
    rowRenderFunction: defaultRowRenderFunction
  },
  [Pages.ActiveDetections]: {
    pageName: Pages.ActiveDetections,
    pageUrl: dalURL + 'GetActiveDetections/',
    detectionFilterLayout: {
      "Detection Name": {field: "name", presets: {}}
    },
    detectionColumns: [
      { key: 'dateTime', name: 'Timestamp (UTC)', fieldName: 'date_time_utc', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'workload', name: 'Workload', fieldName: 'workload', minWidth: 100, maxWidth: 100, isResizable: true },
      { key: 'targetEntity', name: 'Target Entity', fieldName: 'target_entity', minWidth: 100, maxWidth: 300, isResizable: true },
      { key: 'detectionType', name: 'Detection Name', fieldName: 'name', minWidth: 100, maxWidth: 250, isResizable: true },
      { key: 'status', name: 'Status', fieldName: 'analysis_status', minWidth: 50, maxWidth: 50, isResizable: true },
      ],
    renderFunction: defaultRenderFunction,
    rowRenderFunction: defaultRowRenderFunction
  },
  [Pages.LabelDetectionHistory]: {
    pageName: Pages.LabelDetectionHistory,
    pageUrl: dalURL + "GetDetectionLabelHistory/",
    detectionFilterLayout: {
      "Status": {field: "analysis_status", presets: {}},
      "Substatus": {field: "analysis_substatus", presets: {}},
      "Detection Name": {field: "name", presets: {}}
    },
    detectionColumns: [
      { key: 'count', name: 'Count', fieldName: 'score', minWidth: 25, maxWidth: 50, isResizable: true },
      { key: 'dateTime', name: 'Date', fieldName: 'detection_result_time_bin', minWidth: 100, maxWidth: 125, isResizable: true },
      { key: 'workload', name: 'Workload', fieldName: 'workload', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'targetEntity', name: 'Target Entity', fieldName: 'target_entity', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'detectionType', name: 'Detection Name', fieldName: 'name', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'label', name: 'Message', fieldName: 'label', minWidth: 150, maxWidth: 200, isResizable: true }
    ],
    renderFunction: labelDetectionHistoryRenderFunction,
    rowRenderFunction: workloadRowRenderFunction
  },
  [Pages.LabelThrottledDetectionHistory]: {
    pageName: Pages.LabelThrottledDetectionHistory,
    pageUrl: dalURL + "GetThrottledUrgentAlerts/",
    detectionFilterLayout: {
      "Threshold": {field: "threshold", presets: {}},
      "Workload": {field: "workload", presets: {}},
      "Detection Name": {field: "detectionType", presets: {}},
    },
    detectionColumns: [
      { key: 'workload', name: 'Workload', fieldName: 'workload', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'ingestionTime', name: 'IngestionTime', fieldName: 'ingestionTime', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'detectionType', name: 'Detection Name', fieldName: 'detectionType', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'threshold', name: 'Threshold', fieldName: 'threshold', minWidth: 100, maxWidth: 200, isResizable: true },
      { key: 'message', name: 'Message', fieldName: 'message', minWidth: 150, maxWidth: 200, isResizable: true }
    ],
    renderFunction: defaultRenderFunction,
    rowRenderFunction: workloadRowRenderFunction
  }
}