import { attr, Model, ModelType } from 'redux-orm'
import { DetectionFilterDataModelActionTypes } from './DetectionFilterActions';
import { DetectionFilterTableField } from './DetectionFilterInterface';
import * as actions from "../../Constants/Actions";

// @ts-ignore
export class DetectionFilterTable extends Model<typeof DetectionFilterTable, DetectionFilterTableField> {
    static modelName = 'DetectionFilterTable' as const;
    static fields = {
        uniqueIdentifier: attr(),
        detectionFilterOwner: attr(),
        filterSubGroup: attr(),
        filterName: attr(),
        applied: attr(),
        ctrlApplied: attr()
    };

    static options = {
        idAttribute: 'uniqueIdentifier' as const
    };

    // @ts-ignore
    static reducer(action: DetectionFilterDataModelActionTypes, detectionTable: ModelType<DetectionFilterTable>) {
        switch (action.type) {
            case actions.ADD_DETECTIONS_FILTER:
                action.detectionFilters.forEach(val => {
                    detectionTable.create(val);
                })
                break;
            case actions.UPDATE_DETECTIONS_FILTER:
                const tableEntry = detectionTable.withId(action.filter.uniqueIdentifier)
                if (tableEntry != null)
                    tableEntry.update(action.filter)
                else
                    detectionTable.create(action.filter);
                break;
            default:
                break;
        }
    }
}







