import { DefaultButton, IconButton, IStackItemStyles, ITextField, Label, PrimaryButton, Stack, TextField } from 'office-ui-fabric-react';
// Fabric UI
import React from 'react';
import { Ref } from 'redux-orm';
import { DetectionTableFields } from '../../Redux/StateModel/DetectionModel/DetectionModel';
import { EngagementInfoTableFields } from '../../Redux/StateModel/EngagementInfoModel/EngagementInfoModel';
import { currentOncallLink, servicetreeLink, OSI } from '../../Redux/Constants/WorkloadInfoConstants';
import { WorkloadTable } from '../../Redux/StateModel/WorkloadModel/WorkloadModel';
import { WorkloadIcmHistory } from './WorkloadIcmHistory';
import { IMsalContext } from '@azure/msal-react';

export interface IProps {
  // @ts-ignore
  workloadInfo: Ref<WorkloadTable>;
  engagementInfo: EngagementInfoTableFields;
  icmHistory: DetectionTableFields[];
  saveEngagementInfoChanges: (contextType: IMsalContext, newEngagementInfo: EngagementInfoTableFields) => Promise<any>;
  contextType: IMsalContext;
}

export interface IcmTenantTeam {
  TenantName: string,
  TeamName: string
}

type localState = {
  canEditInformation: boolean;
  isSavingChanges: boolean;
  editedEngagementInfo: EngagementInfoTableFields;
}

// Styles
const stackTokens = { childrenGap: 15, padding: 10 };

const infoStackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    width: '100%'
  },
};

const headerStyle = { display: 'flex', alignItems: 'center', };

const labelStyles = { root: { color: 'red', margin: '10px', border: '1px solid', padding: '5px' } };

export class WorkloadInfo extends React.Component<IProps, localState> {
  // component references
  private _engagementNotesInput = React.createRef<ITextField>();

  constructor(cProps: IProps) {
    super(cProps);
    this.state = {
      canEditInformation: false,
      isSavingChanges: false,
      editedEngagementInfo: { ...this.props.engagementInfo }
    };

    this.FormEntry = this.FormEntry.bind(this); //binding allows access to this within function call of FormEntry
    this.saveChangesToForm = this.saveChangesToForm.bind(this);
    this.resetChanges = this.resetChanges.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  // returns a Stack component with a textfield and copy to clipboard button that copies the value in the textfield
  private FormEntry(props: any) {
    return (
      <Stack horizontal verticalAlign="center">
        <Stack.Item grow={1}>
          <TextField label={props.label}
            defaultValue={props.defaultValue}
            description={props.description} readOnly />
        </Stack.Item>
        <Stack.Item>
          <IconButton iconProps={{ iconName: 'copy' }} className="copyButton" onClick={() => this.copyTextToClipboard(props.defaultValue)} />
        </Stack.Item>
      </Stack>
    );
  }


  private copyTextToClipboard(text: string) {
    navigator.clipboard.writeText(text);
  }

  private resetChanges() {
    const answer = window.confirm("Are you sure you want to cancel?");
    if (answer) {
      // undo any changes in form and change state accordingly
      this.setState({
        canEditInformation: false,
        editedEngagementInfo: { ...this.props.engagementInfo }
      });
    }
    return;
  }

  // For current phase, only making changes to engagement notes will be enabled
  private saveChangesToForm() {
    if (!this.state.canEditInformation) {
      alert("Sorry you do not have permissions to save.");
      return;
    }
    if (this.props.engagementInfo.engagementNotes === this.state.editedEngagementInfo.engagementNotes) {
      alert("No new information to save.");
      return;
    }

    if (this.props.engagementInfo.engagementNotes !== this.state.editedEngagementInfo.engagementNotes) {

      // set up current information to match the format of EngagementTableFields
      const updatedInfo: EngagementInfoTableFields = {
        ...(this.props.engagementInfo) as EngagementInfoTableFields,
        engagementNotes: this.state.editedEngagementInfo.engagementNotes
      };

      const resultAction = this.props.saveEngagementInfoChanges(this.props.contextType, updatedInfo);
      resultAction.then(response => {
        this.setState({
          isSavingChanges: true,
          canEditInformation: false
        });
      })
        .catch(err => {
          console.error('Failed to save changes: ', err);
          this.handleFailedSave();
        })
        .finally(() => {
          this.setState({ isSavingChanges: false, canEditInformation: false });
        });
    }
  }

  private handleFailedSave() {
    alert("Unable to save post");
    // revert back any changes made
    this.setState({
      editedEngagementInfo: { ...this.props.engagementInfo }
    });
  }

  private handleChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let newEngagementInfo = { ...this.state.editedEngagementInfo }
    newEngagementInfo.engagementNotes = (event.target as HTMLInputElement).value
    this.setState({
      editedEngagementInfo: newEngagementInfo
    });
  }

  public render() {
    return (
      <form>
        <div style={headerStyle}>
          <h1>{this.props.workloadInfo.workload}</h1>
          {this.props.workloadInfo.deploymentFramework == OSI ?
            <Label styles={labelStyles}>Engage via ICM Request Assistance</Label> : null
          }
        </div>
        <Stack horizontal>
          {/* left column (has contact info) */}
          <Stack.Item styles={infoStackItemStyles}>
            <Stack tokens={stackTokens} key={this.props.workloadInfo.workload} grow={1}>
              <Stack.Item>
                <this.FormEntry label="Servicetree id" defaultValue={this.props.workloadInfo.servicetreeId}
                  description="Servicetree as stored in workload info table." />
                {this.props.workloadInfo.servicetreeId ?
                  <a href={servicetreeLink + this.props.workloadInfo.servicetreeId} target="_blank">
                    View metadata for this service
                  </a>
                  : null
                }
              </Stack.Item>
              <Stack.Item>
                <this.FormEntry label="Inferred Servicetree Id" defaultValue={this.props.engagementInfo.serviceTreeId}
                  description="This is just an inferred id and may not be correct" />
                {this.props.engagementInfo.serviceTreeId ?
                  <a href={servicetreeLink + this.props.engagementInfo.serviceTreeId} target="_blank">
                    View metadata for this service
                  </a>
                  : null
                }
              </Stack.Item>
              <Stack.Item>
                <this.FormEntry label="Distribution List" defaultValue={this.props.workloadInfo.distributionList}
                  description="DL as stored in workload info table." />
              </Stack.Item>
              <Stack.Item>
                <this.FormEntry label="Inferred Distribution List" defaultValue={this.props.engagementInfo.serviceTreeDL.ServiceTeamDL}
                  description="This is the distribution list (DL) associated with the inferred servicetree id above." />
              </Stack.Item>

              <Stack.Item>
                <this.FormEntry label="IcM team" defaultValue={this.props.workloadInfo.icmTeam}
                  description="This is the IcM team as stored in Workload Info table. If this seems wrong, please try looking at the other possible IcM teams below." />
              </Stack.Item>
              <Stack.Item>
                <Label>IcM Emergency Security Broadcast</Label>
                <this.FormEntry label="Service (Tenant) Name" defaultValue={this.props.engagementInfo.serviceTreeIcMTenantTeam.TenantName} description="IcM Service aka Tenant of the designated Security team"/>
                <this.FormEntry label="Team Name" defaultValue={this.props.engagementInfo.serviceTreeIcMTenantTeam.TeamName}
                  description="This is the icm team to reach out to for security incidents." />
                {this.props.engagementInfo.serviceTreeIcMTenantTeam.TeamId && this.props.engagementInfo.serviceTreeIcMTenantTeam.TeamId > 0 ?
                  <a href={currentOncallLink(this.props.engagementInfo.serviceTreeIcMTenantTeam.TenantId,
                    this.props.engagementInfo.serviceTreeIcMTenantTeam.TeamId)} target="_blank">
                    Current On-call
                  </a> : null
                }
              </Stack.Item>

              <Stack.Item>
                <TextField maxLength={250} componentRef={this._engagementNotesInput} label="Engagement Note" value={this.state.editedEngagementInfo.engagementNotes} onChange={this.handleChange} resizable={false} multiline rows={5} readOnly={!this.state.canEditInformation} />
              </Stack.Item>

              {this.state.canEditInformation ?
                <Stack.Item>
                  <PrimaryButton onClick={this.saveChangesToForm} text='Save' />
                  <DefaultButton onClick={this.resetChanges} text='Cancel' />
                </Stack.Item>
                : <PrimaryButton onClick={() => { this.setState({ canEditInformation: true }) }} text='Edit information' />}
            </Stack>
          </Stack.Item>

          <Stack.Item>
            <Stack tokens={stackTokens}>
              <Stack.Item>
                <Label>IcM History</Label>
                <WorkloadIcmHistory incidentLists={this.props.icmHistory} />
              </Stack.Item>
            </Stack>
          </Stack.Item>

        </Stack>
      </form>
    );
  }

}
