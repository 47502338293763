import { IMsalContext } from '@azure/msal-react';
import { Action } from 'redux';
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "..";
import { addAadTokenToGetRequest } from '../../adal/ApiCall';
import { dalbaseURL } from '../../vanquishConfig';
import { addWorkloads, WorkloadTableFields } from "../StateModel/WorkloadModel/WorkloadModel";


export interface Workload {
  CurrentStatus: string;
  Workload: string;
  ServiceTreeId: string;
  DistributionList: string;
  IncidentManagementTeam: string;
  AdditionalData: { [key: string]: string; };
}


export function thunkAddAllWorkloadInfo(contextType: IMsalContext) {
  return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    const workloadsLink = dalbaseURL + "c2api/C2Detection/GetWorkloads/";
    const apiCallWorkloadsLinkData = (await addAadTokenToGetRequest(contextType, workloadsLink)) as Workload[];

    const flattenedWorkloads: WorkloadTableFields[] = Object.values(apiCallWorkloadsLinkData).map(workloadEntry => {
      return {
        currentStatus: workloadEntry.CurrentStatus,
        workload: workloadEntry.Workload,
        servicetreeId: workloadEntry.ServiceTreeId,
        distributionList: workloadEntry.DistributionList,
        icmTeam: workloadEntry.IncidentManagementTeam,
        deploymentFramework: workloadEntry.AdditionalData["DeploymentFramework"] ? workloadEntry.AdditionalData["DeploymentFramework"] : ""
      };
    })
    dispatch(addWorkloads(flattenedWorkloads))
  }
}
