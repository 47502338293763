// import { catch } from 'fetch-mock';
import { IMsalContext } from '@azure/msal-react';
import { Action } from 'redux';
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "..";
import { addAadTokenToGetRequest, addAadTokenToPostRequest } from '../../adal/ApiCall';
import { dalbaseURL } from '../../vanquishConfig';
import { addEngagementInfo, updateEngagementInfo, EngagementInfoTableFields } from "../StateModel/EngagementInfoModel/EngagementInfoModel";

// maps to the SQL model database (the propereties should exactly match the columns of the corresponding table in SQL)
export interface EngagementInfo {
  ServiceEntityType: string;
  ServiceEntityName: string;
  ServiceTreeId: string;
  IncidentManagementTeams: any;
  EngagementNotes: string;
  ServiceTreeDistributionList: any;
  ServiceTreeIcMTenantTeam: any;
}

// Handles parsing of string representation of JSON where the values may contain null values
export function parseJSONWithNull(jsonString: string) {
  try{
    if(jsonString === null) return "";

    const replacedJsonStr: string = jsonString.replace("null", "\"\"");
    return JSON.parse(replacedJsonStr);
  }
  catch(Error){
    return jsonString;
  }
}

export function thunkLoadAllEngagementInfo(contextType: IMsalContext) {
  return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    
    const getEngagementInfoLink = dalbaseURL + "c2api/C2Detection/GetAllServiceEntityEngagementInfo";
    const apiCallGetAllEngagementInfo = (await addAadTokenToGetRequest(contextType, getEngagementInfoLink)) as EngagementInfo[];

    const flattenedEngagementInfo: EngagementInfoTableFields[] = Object.values(apiCallGetAllEngagementInfo).map(info => {
      return {
        serviceEntityType: info.ServiceEntityType || "",
        serviceEntityName: info.ServiceEntityName || "",
        serviceTreeId: info.ServiceTreeId || "",
        icmTeams: info.IncidentManagementTeams === null || info.IncidentManagementTeams === "" ? {}: parseJSONWithNull(info.IncidentManagementTeams),
        engagementNotes: info.EngagementNotes,
        serviceTreeDL: parseJSONWithNull(info.ServiceTreeDistributionList) || {ServiceTeamDL: ""},
        serviceTreeIcMTenantTeam: info.ServiceTreeIcMTenantTeam === null || info.ServiceTreeIcMTenantTeam === "" ? {} : parseJSONWithNull(info.ServiceTreeIcMTenantTeam)
      }
    });
    dispatch(addEngagementInfo(flattenedEngagementInfo));
  }
}

export function thunkSaveEngagementInfoChanges(contextType: IMsalContext, newEngagementInfo: EngagementInfoTableFields) {
  return async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    // make DAL Api call here 
    const updateLink = dalbaseURL + "c2api/C2Detection/UpdateServiceEntityEngagementInfo";

    const requestBody = {
      "ServiceEntityType": newEngagementInfo.serviceEntityType,
      "ServiceEntityName": newEngagementInfo.serviceEntityName,
      "serviceTreeId": newEngagementInfo.serviceTreeId,
      "IncidentManagementTeams": JSON.stringify(newEngagementInfo.icmTeams),
      "EngagementNotes": newEngagementInfo.engagementNotes,
      "ServiceTreeDistributionList": JSON.stringify(newEngagementInfo.serviceTreeDL),
      "ServiceTreeIcMTenantTeam": JSON.stringify(newEngagementInfo.serviceTreeIcMTenantTeam)
    };

    const apiCallUpdateEngagementInfo = (await addAadTokenToPostRequest(contextType, updateLink, requestBody));
    dispatch(updateEngagementInfo(newEngagementInfo));
    return apiCallUpdateEngagementInfo;
  };
}
