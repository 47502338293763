import React from 'react';
// Fabric UI
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
//Store
import { argumentFromUrl } from '../OCEDashBoard/OCEDashBoard';
import DetectionPageContainer from '../../containers/DetectionPageContainer';
import { DetectionListConstant, Pages } from '../../Redux/Constants/GeneralConstants';
import { getDetectionConstant } from '../../Redux/Constants/DetectionListConstants';
import { TextField } from 'office-ui-fabric-react';
import { IMsalContext, withMsal, WithMsalProps } from '@azure/msal-react';
import { query } from '../LoadQueryFromCode/queries/mdid';


export interface urlLinks {
  EntityDetails: string;
}

export interface IProps {
  urlParameters: argumentFromUrl;
  query: string;
  detectionListConstant: DetectionListConstant;
  loadDetectionsPostApiCall: (msalContext: IMsalContext, detectionListConstant: DetectionListConstant, options: any) => void;
}

type localState = {
  kustoQuery: string;
}

const _getKeyValue_ = (key: string) => (obj: Record<string, any>) => obj[key];


class LoadDetectionFromKusto extends React.Component<IProps & WithMsalProps, localState>{
  readonly state: localState = {
    kustoQuery: this.props.query
  }



  public updateField(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string){
    if(newValue !== undefined)
      this.setState({kustoQuery:newValue})
  }
  

  async call(){
    const query = {"db":"security_signals_realtime","csl":this.state.kustoQuery,"properties":{"Options":{"servertimeout":"00:04:00","queryconsistency":"strongconsistency","query_language":"csl","request_readonly":false,"request_readonly_hardline":false}}}
    try{
      this.props.loadDetectionsPostApiCall(
        this.props.msalContext, 
        this.props.detectionListConstant,
        query)
    } catch(e) { 
      console.error(e); 
    }
    const a = 5;
    

    
  }

  public render(): JSX.Element {
    return (
    <div>
    <TextField value = {this.state.kustoQuery} multiline rows={7}  onChange={this.updateField.bind(this)}/>
    <DefaultButton
                  onClick={this.call.bind(this)}
                  text={"Query Kusto"}
                />

    <DetectionPageContainer
      urlParameters={this.props.urlParameters}
      detectionListConstant={this.props.detectionListConstant}
      msalContext={this.props.msalContext}
    /></div>
    );
  }
}

export default withMsal(LoadDetectionFromKusto);
