import { connect } from 'react-redux';
import { Action } from 'redux';
import { Moment } from 'moment';
import { ThunkDispatch } from 'redux-thunk';
import { pullDataMethod } from '../Redux/StateModel/DetectionPageModel/DetectionPageModel'

// Store
import { AppState } from '../Redux';
import { thunkLoadDetectionsApiCall, importICMData } from '../Redux/ApiCalls/DetectionActions';

// Components
import { getDetectionsPageByOwner } from '../Redux/StateModel/DetectionPageModel/DetectionPageSelector';
import DatePickerDetectionPage from '../components/DetectionList/SupportingObjects/DatePicker';
import { DetectionListConstant } from '../Redux/Constants/GeneralConstants';
import { IMsalContext } from '@azure/msal-react/dist/MsalContext';

interface IOwnProps {
  detectionListConstant: DetectionListConstant;
}

const mapStateToProps = (state: AppState, ownProps: IOwnProps) => {
  return {
    detectionPageInfo: getDetectionsPageByOwner({ detectionPageTable: state.orm.DetectionPageTable, detectionOwner: ownProps.detectionListConstant.pageName }),
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
    silentThunkLoadDetectionsApiCall: (msalContext: IMsalContext, startTime: Moment, stopTime: Moment, extraParameters: string, detectionListConstant: DetectionListConstant, pullDataMethod: pullDataMethod) =>
      dispatch(thunkLoadDetectionsApiCall(msalContext, startTime, stopTime, extraParameters, detectionListConstant, pullDataMethod)),
    importICMData: (msalContext: IMsalContext, startTime: Moment, stopTime: Moment, owningTeam: string, extraParameters: string, detectionListConstant: DetectionListConstant, pullDataMethod: pullDataMethod) => 
    dispatch(importICMData(msalContext, startTime, stopTime, owningTeam, extraParameters, detectionListConstant, pullDataMethod)),
  }
}

const DatePickerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DatePickerDetectionPage);

export default DatePickerContainer;
