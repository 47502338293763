import { attr, Model, ModelType } from 'redux-orm';
import * as actions from '../../Constants/Actions';


export interface DetectionSummaryTableField {
    Detection_Type: string;
    Name: string;
    Description: string;
    Grouping_Fields: string[];
    Visible_Fields: string[];
    Detection_Tags: string[];
    Detection_Tsg: string;
}

interface AddDetectionSummaryItemsAction {
    type: typeof actions.ADD_DETECTIONS_SUMMARY;
    detectionData: DetectionSummaryTableField[];
}

export type DetectionSummaryDataModelActionTypes = AddDetectionSummaryItemsAction

//Action Functions
export function addDetectionSummaryData(detectionData: DetectionSummaryTableField[]): DetectionSummaryDataModelActionTypes {
    return {
        type: actions.ADD_DETECTIONS_SUMMARY,
        detectionData
    }
}

// @ts-ignore
export class DetectionSummaryTable extends Model<typeof DetectionSummaryTable, DetectionSummaryTableField> {
    static modelName = 'DetectionSummaryTable' as const;
    static fields = {
        Name: attr(),
        Description: attr(),
        Grouping_Fields: attr(),
        Visible_Fields: attr(),
        Detection_Tags: attr(),
        Detection_Tsg: attr(),
    };

    static options = {
        idAttribute: 'Detection_Type' as const
    };

    // @ts-ignore
    static reducer(action: DetectionSummaryDataModelActionTypes, detectionPageTable: ModelType<DetectionSummaryTable>) {
        switch (action.type) {
            case actions.ADD_DETECTIONS_SUMMARY:
                action.detectionData.forEach(entry => {
                    detectionPageTable.create(entry);
                })
                break;

            default:
                break;
        }
    }
}
