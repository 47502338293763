import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

// Store
import { AppState } from '../Redux';

// Components

import { DetectionListConstant } from '../Redux/Constants/GeneralConstants';
import DetectionListPopupWindow from '../components/DetectionList/SupportingObjects/DetectionListPopupWindow';
import { getFilterSubGroupsByOwner } from '../Redux/StateModel/DetectionModel/DetectionSelector';


interface OwnProps {
  detectionPageData: DetectionListConstant
};

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  return {
    filterSubGroup:getFilterSubGroupsByOwner({
      detectionListFilterTable: state.orm.DetectionFilterTable,
      detectionOwner:ownProps.detectionPageData.pageName
    })
  }
}
const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
  }
}


const DetectionListPopupWindowContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DetectionListPopupWindow);;

export default DetectionListPopupWindowContainer;
