import moment from "moment";
import { IColumn } from "office-ui-fabric-react/lib/components/DetailsList/DetailsList.types";
import { Link } from "office-ui-fabric-react/lib/components/Link";
import React from "react";
import { ReactNode } from "react";
import { engBase, vanquishURL } from "../../vanquishConfig";


export interface PreSetFilter {
  [preSetFillter: string]: boolean
}

export interface DetectionFilter {
  field: string
  removeByDefault? : boolean
  presets: PreSetFilter
}

export interface DetectionFilterLayout {
  [subCatigory: string]: DetectionFilter
};

export interface DetectionListColumns {
  key: string,
  name: string,
  fieldName: string,
  minWidth: number,
  maxWidth: number,
  isResizable: boolean
}

export interface DetectionListConstant {
  pageName: string,
  detectionFilterLayout: DetectionFilterLayout,
  detectionColumns: DetectionListColumns[],
  renderFunction: (item: any, index: number | undefined, column: IColumn | undefined) => ReactNode,
  rowRenderFunction: (props: any, defaultRender: any) => JSX.Element;
  pageUrl: string
};


export interface DetectionListConstants {
  [pageName: string]: DetectionListConstant
};

//------------------------------------------------------

export enum Pages {
  UnTriageAlerts = "UnTriagedPaging",
  ActiveDetections = "ActiveDetections",
  LabelDetectionHistory = "LabelDetectionHistory",
  TriageAlerts = "TriageAlerts",
  LabelThrottledDetectionHistory = "LabelThrottledDetectionHistory",
  PipelineTopology = "PipelineTopology",
  WorkloadInfo = "WorkloadInfo",
  LoadKustoDetections = "LoadKustoDetections",
  LoadQueryFromCode= "LoadQueryFromCode"
}

export interface ITargetEntityInfo {
  targetEntity: string;
  targetEntityType: string;
  detectionType: string;
}

export interface IDetectionInfo {
  entityValue?: string;
  entityType?: string;
  workload?: string;
  detectionType?: string;
  detectionId?: string;
  dateTime?: string;
  machine?: string;
}

export enum Path {
  WorkloadInfos = "/workloadInfos"
}

const defaultLookBackTime = 10;
const formatTime = "YYYY-MM-DDTHH:mm:ss.SSS";

const getTimeRange = (dateTimeUtc: string, detectionType: string): string => {
  if (dateTimeUtc === "")
    return "";

  let detectionTime = moment.utc(dateTimeUtc);
  let startTime = detectionTime.clone().subtract(defaultLookBackTime, "days").format(formatTime);
  if(detectionType === "WorkloadInstance")
  {
    startTime = detectionTime.clone().format(formatTime);
  }
  

  let timeRangeParam = `timeRangeStart=${startTime}Z`;
  return timeRangeParam;
}

const getNormalizedEntityType = (rawEntityType: string | undefined, detectionType: string): string => {
  let trimmedEntityType = rawEntityType != undefined ? rawEntityType.trim() : "";
  switch (trimmedEntityType) {
    case "Process":
      return "ProcessToPivot";
    case "IP":
      return "ip";
    default:
      return trimmedEntityType;
  }
}

const getreportLink = (detectionType: string): string => {
  return 'https://aka.ms/reportvanquishdetection?[System.Title]=[Triage UX] Detection ' + detectionType + ' needs work (please describe)'
}

const metaDetectionTypes = new Set<string>(['WorkloadInstance','AzureIdentityMD', 'AzureResourceIdMD']);

export const createDetectionLink = (detectionOptions: IDetectionInfo): string => {

  const detectionType : string = detectionOptions.detectionType != undefined ? detectionOptions.detectionType : ""
  const entityValue : string = detectionOptions.entityValue != undefined ? detectionOptions.entityValue : ""
  const workload : string = detectionOptions.workload != undefined ? detectionOptions.workload : ""
  const dateTime : string = detectionOptions.dateTime != undefined ? detectionOptions.dateTime : ""

  let entityType = getNormalizedEntityType(detectionOptions.entityType, detectionType);

  let params = `/EntityDetails?api=entity&entitytype=${entityType.trim()}&entity=${entityValue.toString().trim()}&workload=${workload.trim()}`;
  if(detectionType.trim() == "ProcessProfileBasedAnomaly") {
    params = `/EntityDetails?api=entity&entity=${detectionOptions.machine}&workload=${workload.trim()}`
  }
  const timeRange = getTimeRange(dateTime, detectionType);
  if (timeRange !== "") params += `&${timeRange}`;

  if (metaDetectionTypes.has(detectionType)) params += "&mdid=" + detectionOptions.detectionId;

  let link = vanquishURL + params;

  const entityTypesToUseTSG = ["DestinationHostForest", "MachineName", "UserId", "AppUser"];
  if (entityTypesToUseTSG.includes(entityType)) link = vanquishURL + `TroubleshootingGuides/${detectionType.trim()}`;

  return link;
}

export const createDashBoardLink = (detectionOptions: IDetectionInfo): string => {

  const detectionType : string = detectionOptions.detectionType != undefined ? detectionOptions.detectionType : ""
  const entityValue : string = detectionOptions.entityValue != undefined ? detectionOptions.entityValue : ""
  const workload : string = detectionOptions.workload != undefined ? detectionOptions.workload : ""
  const dateTime : string = detectionOptions.dateTime != undefined ? detectionOptions.dateTime : ""
  const detectionId : string = detectionOptions.detectionId != undefined ? detectionOptions.detectionId : ""

  var dashBoardLink = "";
  var baseLink = "https://dataexplorer.azure.com/dashboards/48f090cf-e021-42d1-8459-970e81dfbbec?p-_startTime=14days&p-_endTime=now&"
  if(detectionType == "WorkloadInstance" && detectionId != "")
  {
    dashBoardLink = baseLink + "p-_query=all&p-_id=v-" 
    + detectionId 
    + "#2c0b6ce3-48b0-4062-baca-78a7caa6a589"
  } else if(workload == "ADODetections" && detectionId != "")
  {
    dashBoardLink = baseLink + "p-DetectionId=v-" 
    + detectionId 
    + "&p-SelectedActions=v-Urgent&p-SelectedActions=v-UrgentThrottled#4f194683-ec83-4f82-89e8-fe093df919f1"
  } else if(detectionType == "AzureCommandRunnerExtensionProcess" && detectionId != "")
  {
    dashBoardLink = baseLink + "p-DetectionId=v-" 
    + detectionId 
    + "&p-SelectedActions=v-Urgent&p-SelectedActions=v-UrgentThrottled#4ff558a8-376c-4239-87d5-c5a0c7055981"
  } else if(detectionType == "IdsWmiSuspiciousActivity" && detectionId != "")
  {
    dashBoardLink = baseLink + "p-DetectionId=v-" 
    + detectionId 
    + "&p-SelectedActions=v-Urgent&p-SelectedActions=v-UrgentThrottled&p-_workloadInstance=all#4c8e762c-e6da-4cd3-b678-5858d735a5eb"
  } else if(detectionType == "AzureIdentityMD" && detectionId != "")
  {
    dashBoardLink = baseLink + "p-_query=all&p-_id=v-" 
    + detectionId 
    + "&p-_azIdentity=all#3a469bfd-90e7-4563-9627-12d4b142dd0f"
  } else if(detectionType.includes("AzureKubernetes") && !detectionType.includes("Anomalous") && detectionId != "")
  {
    var aksLink = "https://kusto.azure.com/dashboards/da55aff0-eebb-4f8c-8660-97120bd0e1e4?p-_startTime=7days&p-_endTime=now&"
    dashBoardLink = aksLink + "p-_entity="
    + entityValue
    + "#58033ae7-0f7d-4262-aadf-8af6c4c67d4f"
  } else if(detectionType == "AzureResourceIdMD" && detectionId != "")
  {
    var aksEmdLink = "https://dataexplorer.azure.com/dashboards/a9480f37-46ab-4f7d-a477-e03fa7ce7b50?p-_id=v-"
    dashBoardLink = aksEmdLink + detectionId
  }

  return dashBoardLink;
}

const generateStartTime = (item: any) : string => {
  const detectionType = item["detection_type" as keyof any] as string;
  let time : string = item["date_time_utc" as keyof any] as string;
  if(detectionType === "WorkloadInstance")
  {
    time = item["WindowStartTime" as keyof any] as string
  }
  return time;
}

const getDetectionLinkFromItem = (item: any, column: IColumn | undefined, fieldContent: string, isNewUI: boolean) => {
  const machine = (item["additional_data" as keyof any] as any) != undefined ? (item["additional_data" as keyof any] as any)["MachineToPivot" as keyof any] : ""
    
  const detectionOptions: IDetectionInfo = {
    entityValue: item[column?.fieldName as keyof any] as string,
    entityType: item["target_entity_type" as keyof any] as string,
    workload: item["workload" as keyof any] as string,
    detectionType: item["detection_type" as keyof any] as string,
    detectionId: item["id" as keyof any] as string,
    dateTime: generateStartTime(item),
    machine: machine as string
  };

  let link = createDetectionLink(detectionOptions);
  let dashBoardLink = createDashBoardLink(detectionOptions);

  // preserving only COSMIC cluster name for AzureResourceIdMD detection type
  const SEPARATOR = "/MANAGEDCLUSTERS/";
  if (detectionOptions.detectionType === "AzureResourceIdMD" && fieldContent.includes(SEPARATOR)) {
    let parts = fieldContent.toUpperCase().split(SEPARATOR);
    fieldContent = parts[1].toLowerCase();
  }

  if(dashBoardLink != "")
    return <div>{generateLinkComponent(link, fieldContent)}<br></br> {generateLinkComponent(dashBoardLink, "DashBoard")}</div>
  return <div>{generateLinkComponent(link, fieldContent)}</div>
}

const generateLinkComponentUnknown = (link: string, content: string) => {
  if(content === "")
    return buildSpan("Unknown")
  else 
    return generateLinkComponent(link, content)
}

const generateLinkComponent = (link: string, content: string) => {
  return <Link onClick={() => window.open(link, '_blank')} style={{ overflowWrap: 'break-word', whiteSpace: 'break-spaces' }}>{content}</Link>;
}

const buildSpan = (val: string): JSX.Element => {
  return (<span style={{ overflowWrap: 'break-word', whiteSpace: 'break-spaces' }}>{val}</span>);
}

const buildLinkDivForLabelDetectionHistory = (deduplicatedTargetEntityInfo: ITargetEntityInfo[], item: any) => {
  const workload = item["workload" as keyof any] as string;
  const detectionId = item["id" as keyof any] as string;
  const machine = (item["additional_data" as keyof any] as any) != undefined ? (item["additional_data" as keyof any] as any)["MachineToPivot" as keyof any] : ""
  const targetEntityLinkCell = deduplicatedTargetEntityInfo.map(el => {
    let detectionOptions = {
      entityValue: el.targetEntity, 
      entityType: el.targetEntityType, 
      workload: workload,
      detectionType: el.detectionType, 
      detectionId: detectionId, 
      dateTime: generateStartTime(item),
      machine: machine as string
    };
    let link = createDetectionLink(detectionOptions);
    return <div>{generateLinkComponent(link, el.targetEntity.trim())}</div>
  });
  return targetEntityLinkCell;
}

//How to render individual functions
//------------------------------------------------------


export const defaultRenderFunction = (item: any, index: number | undefined, column: IColumn | undefined) => {
  if (column === undefined)
    return;
  let fieldContent = item[column?.fieldName as keyof any] as string;
  
  switch (column?.key) {

    case 'targetEntity':
    case 'target_entity':
      return getDetectionLinkFromItem(item, column, fieldContent, false);
    case 'detection_type':
    case 'detectionType':
      const detection_type_content = item["detection_type"] as string;
      return generateLinkComponent(engBase + detection_type_content, fieldContent)
    case 'delay':
      return parseFloat(fieldContent).toFixed(2);
    case 'dateTime':
    case 'date_time_utc':
      return moment.utc(fieldContent).format(formatTime);
    case 'icm':
      const ticketNumber = item["IncidentId" as keyof any];
      let icmLink = "https://portal.microsofticm.com/imp/v3/incidents/details/" + ticketNumber + "/home";
      return generateLinkComponentUnknown(icmLink, ticketNumber);
    case 'ingestionTime':
      return moment(fieldContent).utc().format(formatTime);
    case 'timestamp':
      return buildSpan(moment(fieldContent).utc().format(formatTime));
    case 'helpButton':
      let detection_type = item["detection_type" as keyof any] as string;
      return generateLinkComponentUnknown(getreportLink(detection_type), "Report");
    case 'additional_data':
      return (<pre>{buildSpan(fieldContent)}</pre>)
    default:
      return buildSpan(fieldContent);
  }

}

// removes the duplicates in the target entity list and groups the correct target_entity_type and detection_type
// for a target entity
const deduplicateTargetEntityList = (targetEntities: string, targetEntityTypes: string, detectionTypes: string): ITargetEntityInfo[] => {
  const targetEntityInfo: ITargetEntityInfo[] = [];
  const targetEntitySet = new Set();

  const targetEntityList = targetEntities.split("\n");
  const targetEntityTypeList = targetEntityTypes.split("\n");
  const detectionTypeList = detectionTypes.split("\n");

  targetEntityList.forEach((targetEntity, index) => {
    if (!targetEntitySet.has(targetEntity)) {
      targetEntitySet.add(targetEntity);
      let entityType = targetEntityTypeList[index] == null ? "" : targetEntityTypeList[index];
      let detectionType = detectionTypeList[index] == null ? "" : detectionTypeList[index];
      let targetInfoObject = { "targetEntity": targetEntity, "targetEntityType": entityType, "detectionType": detectionType };
      targetEntityInfo.push(targetInfoObject);
    }
  });

  return targetEntityInfo;
}

export const labelDetectionHistoryRenderFunction = (item: any, index: number | undefined, column: IColumn | undefined) => {
  if (column === undefined)
    return;

  const fieldContent = (item[column?.fieldName as keyof any] as string);

  // deduplicate target_entities
  const targetEntities: string = item["target_entity" as keyof any] as string;
  const targetEntityTypes: string = item["target_entity_type" as keyof any] as string;
  const detectionTypes: string = item["detection_type" as keyof any] as string;
  const deduplicatedTargetEntityInfo = deduplicateTargetEntityList(targetEntities, targetEntityTypes, detectionTypes);

  switch (column?.key) {
    case 'targetEntity':
      return buildLinkDivForLabelDetectionHistory(deduplicatedTargetEntityInfo, item);
    case 'detectionType':
      const allDetectionTypes: string = deduplicatedTargetEntityInfo.map(el => el.detectionType).join("\n");
      return buildSpan(allDetectionTypes);
    case 'dateTime':
      return moment(fieldContent).utc().format(formatTime);
    default:
      return buildSpan(fieldContent);
  }
}



//-----------------------------------------------------

const getRowHighlight = (itemIndex: number): string => {
  if (itemIndex % 2 === 0) {
    return "#fafafa";
  }
  return "white";
}

export const defaultRowRenderFunction = (props: any, defaultRender: any): JSX.Element => {
  return (
    <div> {defaultRender({
      ...props,
      className: "ms-fontSize-14",
      styles: {
        root: {
          background: getRowHighlight(props.itemIndex),
          color: "NeutralColors.black",
          userSelect: 'auto'
        }
      }
    })
    }</div>)
}

export const workloadRowRenderFunction = (props: any, defaultRender: any): JSX.Element => {
  return defaultRender(props)
}
//Row layout for pages
//------------------------------------------------------


