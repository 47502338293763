import { attr, Model, ModelType } from 'redux-orm';
import * as actions from "../../Constants/Actions";

//Interfaces
export interface WorkloadTableFields {
    currentStatus: string;
    workload: string;
    servicetreeId: string;
    distributionList: string;
    icmTeam: string;
    deploymentFramework: string;
}

// Action types

export interface AddAllWorkloadsAction {
    type: typeof actions.ADD_WORKLOADS; //adds workloads and all of its relevant information for engagement
    workloads: WorkloadTableFields[];
}

export interface GetWorkloadInfo {
    type: typeof actions.GET_WORKLOAD;
    workload: WorkloadTableFields;
}

export type WorkloadDataModelActionTypes = AddAllWorkloadsAction | GetWorkloadInfo;


//Action Functions
export function addWorkloads(workloads: WorkloadTableFields[]): WorkloadDataModelActionTypes {
    return {
        type: actions.ADD_WORKLOADS,
        workloads
    }
}

// @ts-ignore
export class WorkloadTable extends Model<typeof WorkloadTable, WorkloadTableFields> {
    static modelName = 'WorkloadTable' as const;
    static fields = {
        currentStatus: attr(),
        workload: attr(),
        servicetreeId: attr(),
        distributionList: attr(),
        icmTeam: attr(),
        deploymentFramework: attr()
    };

    static options = {
        idAttribute: 'workload' as const
    };

    // @ts-ignore
    static reducer(action: WorkloadDataModelActionTypes, workloadTable: ModelType<WorkloadTable>) {
        switch (action.type) {
            case actions.ADD_WORKLOADS:
                action.workloads.forEach(val => {
                    workloadTable.create(val);
                })
                break;
            default:
                break;
        }
    }
}
