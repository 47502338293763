import { attr, Model, ModelType } from 'redux-orm'
import * as actions from "../../Constants/Actions";

export interface DetectionTableInput {
    [extraField: string]: any;
}

export interface DetectionTableFields {
    //This is who entered this detection, for example a page
    uniqueId: string;
    detectionOwner: string;
    isSelected: boolean;
    [extraField: string]: any;
}

export interface AddDetectionListItemsAction {
    type: typeof actions.ADD_DETECTIONS;
    detectionData: DetectionTableInput[];
    detectionOwner: string;
    isSelected: boolean;
}

export interface DetectionSelectionInfo {
    uniqueIds: string;
    isSelected: boolean;
}

export interface SelectDetectionListItemsAction {
    type: typeof actions.SELECT_DETECTIONS,
    selectionChange: DetectionSelectionInfo[]
}

export interface UnSelectAllDetectionsAction {
    type: typeof actions.UNSELECT_ALL_DETECTIONS;
}

export type DetectionDataModelActionTypes = AddDetectionListItemsAction | SelectDetectionListItemsAction | UnSelectAllDetectionsAction;

//Action Functions
export function addDetectiondata(detectionData: DetectionTableInput[], detectionOwner: string): DetectionDataModelActionTypes {
    return {
        type: actions.ADD_DETECTIONS,
        detectionData,
        detectionOwner,
        isSelected: false
    }
}



export function selectDetectionData(selectionChange: DetectionSelectionInfo[]): DetectionDataModelActionTypes {
    return {
        type: actions.SELECT_DETECTIONS,
        selectionChange
    }
}


export function unSelectAllDetections(): DetectionDataModelActionTypes {
    return {
        type: actions.UNSELECT_ALL_DETECTIONS
    }
}

// @ts-ignore
export class DetectionTable extends Model<typeof DetectionTable, DetectionTableFields> {
    static modelName = 'DetectionTable' as const;
    static fields = {
        detectionOwner: attr(),
        isSelected: attr()
    };

    static options = {
        idAttribute: 'uniqueId' as const
    };

    static guidGenerator() {
        var S4 = function() {
           return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
        };
        return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
    }

    // @ts-ignore
    static reducer(action: DetectionDataModelActionTypes, detectionTable: ModelType<DetectionTable>) {
        switch (action.type) {
            case actions.ADD_DETECTIONS:
                detectionTable.all().filter(detection => detection.detectionOwner == action.detectionOwner).delete();
                action.detectionData.forEach(val => {
                    //Append detection owner to the object and insert it into the table
                    const uniqueId = DetectionTable.guidGenerator();
                    detectionTable.create({
                        ...val,
                        detectionOwner: action.detectionOwner,
                        isSelected: action.isSelected,
                        uniqueId
                    });
                })
                break;
            case actions.SELECT_DETECTIONS:
                action.selectionChange.forEach(detectionChange => {
                    const detection = detectionTable.withId(detectionChange.uniqueIds);
                    if (detection !== null)
                    {
                        detection.update({isSelected: detectionChange.isSelected})
                    }
                })
                break;
            case actions.UNSELECT_ALL_DETECTIONS:
                detectionTable.all().filter(detection => (detection.isSelected == true)).toModelArray().forEach(detection=>{
                    detection.update({
                        isSelected: false
                    });
                })
                break;
            default:
                break;
        }
    }
}







