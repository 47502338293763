import React from 'react';
import './style.css';

// Components

// Containers

// Fabric UI
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Ref } from 'redux-orm';
import DetectionListFilterToggleContainer from '../../../containers/DetectionListFilterToggleContainer';
import { DetectionFilterTable } from '../../../Redux/StateModel/DetectionFilterModel/DetectionFilterModel';
import { DetectionListConstant } from '../../../Redux/Constants/GeneralConstants';

export interface IProps {
  label: string,
  filter: string,
  selectedButton: boolean,
  // @ts-ignore
  filters: Ref<DetectionFilterTable>[],
  detectionPageData: DetectionListConstant,
}

// Used to add spacing between example checkboxes
const stackTokens = { childrenGap: 5 };

type localState = {

};

class DetectionListFilter extends React.Component<IProps, localState>{

  readonly state: localState = {
    isQueryDialogOpen: false,
    isFilterPanelOpen: false,
    selectStatus: "",
    comment: "",
  };

  public render(): JSX.Element {
    const filters = this.props.filters.filter(filter => filter.applied === this.props.selectedButton)
    const label = this.props.label
    let fieldName = (<div></div>);
    if (filters.length > 0)
      fieldName = (<p><strong>{label}</strong></p>);
    return (
      <Fabric>
        {fieldName}
        <Stack tokens={stackTokens}>
          {
            // @ts-ignore
            filters.map((value: Ref<DetectionFilterTable>, index: number) => {
              return (
                <DetectionListFilterToggleContainer filter={value} detectionPageData={this.props.detectionPageData} />
              )
            })}
        </Stack>
      </Fabric>
    );
  }
}

export default DetectionListFilter;

