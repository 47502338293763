import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

// Store
import { AppState } from '../Redux';
import { DetectionResultIdArray, thunkUpdateStatusDialogOpen } from "../Redux/ApiCalls/DetectionListToolbarActions";

// Components
import { getSelectedDetectionPostData } from '../Redux/StateModel/DetectionModel/DetectionSelector';
import { DetectionListConstant } from '../Redux/Constants/GeneralConstants';
import DetectionListLabeler from '../components/DetectionList/SupportingObjects/DetectionListLabeler';
import { IMsalContext } from '@azure/msal-react';

interface OwnProps {
  detectionListConstant: DetectionListConstant;
}

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  return {
    detectionResultId: getSelectedDetectionPostData({
      detectionTable: state.orm.DetectionTable,
      detectionSummaryTable: state.orm.DetectionSummaryTable,
      detectionOwner: ownProps.detectionListConstant.pageName,
      icmTable: state.orm.ICMTable,
      detectionListFilterTable: state.orm.DetectionFilterTable,
    })
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
    thunkUpdateStatusDialogOpen: (msalContext: IMsalContext, isStatusDialogOpen: boolean, selectStatus: string, comment: string, detectionResultIdArray: DetectionResultIdArray) =>
      dispatch(thunkUpdateStatusDialogOpen(msalContext, isStatusDialogOpen, selectStatus, comment, detectionResultIdArray)),
  }
}


const DetectionListLabelerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DetectionListLabeler);

export default DetectionListLabelerContainer;
